import { useTranslation } from 'react-i18next'
import { type RouteObject, useLocation, useRoutes } from 'react-router-dom'

import { RoutePaths } from '@/enums'
import { createDeepPath } from '@/helpers'

// HACK: reqact-router v6 doesn't support absolute paths in the nested routes
// Discussion: https://github.com/remix-run/react-router/discussions/9841
export const useNestedRoutes = (root: RoutePaths, routes: RouteObject[]) => {
  return useRoutes(
    routes.map(({ path, ...rest }) => ({
      path: path && createDeepPath(path.replace(root, '')),
      ...rest,
    })),
  )
}

export const useRouteTitle = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const routePathToTitle: { [key in RoutePaths]?: string } = {
    [RoutePaths.Market]: t('routes.market'),
    [RoutePaths.Account]: t('routes.account'),
    [RoutePaths.Leaderboard]: t('routes.leaderboard'),
  }
  const pathKey = Object.keys(routePathToTitle).find(pattern => pathname.startsWith(pattern))
  return routePathToTitle[pathKey as RoutePaths] ?? ''
}
