import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icons, Illustrations } from '@/enums'
import { Transitions } from '@/theme/constants'
import { UiIcon } from '@/ui'
import UiIllustration from '@/ui/UiIllustration'

export default function CommunitySection() {
  const { breakpoints } = useTheme()
  const { t } = useTranslation()

  return (
    <Box
      component='section'
      data-aos='fade-up'
      data-aos-anchor-placement='center-bottom'
      sx={{
        mt: { md: 10 },
        pt: { xs: 10, md: 30 },
        pb: { xs: 20, md: 60 },
        display: 'grid',
        justifyContent: 'center',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        alignItems: 'center',
        gap: { xs: 6, md: 15 },
        [breakpoints.down('sm')]: {
          gridTemplateColumns: '1fr',
        },
      }}
    >
      <Stack
        sx={({ palette }) => ({
          background: palette.action.active,
          aspectRatio: '3/2',
          width: '100%',
          borderRadius: 4,
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        <ChatAnimation />
      </Stack>
      <Typography
        variant='subtitle1'
        sx={({ typography }) => ({ typography: { xs: typography.h4 } })}
        maxWidth={380}
      >
        {t('home-page.community-title')}
      </Typography>
    </Box>
  )
}

enum AnimationStates {
  idle = 'idle',
  greenActive = 'green-active',
  redActive = 'red-active',
}

const ChatAnimation = () => {
  const { palette } = useTheme()
  const [animationState, setAnimationState] = useState(AnimationStates.idle)

  const updateAnimation = (animation: AnimationStates) => {
    setAnimationState(prev => (prev === animation ? AnimationStates.idle : animation))
  }

  const rowHeightMap: Record<AnimationStates, number> = {
    [AnimationStates.redActive]: 2,
    [AnimationStates.greenActive]: 8,
    [AnimationStates.idle]: 4,
  }

  const baseRowStyles = {
    background: palette.common.black,
    color: palette.common.white,
    position: 'absolute',
    transition: Transitions.Gentle,
    borderRadius: 8,
    height: rowHeightMap[animationState],
  }

  const baseBoxStyles = {
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: Transitions.Gentle,
    height: 80,
    width: 95,
    borderRadius: 3,
    border: `${palette.common.black} 1px solid`,
  }

  const biggerBoxProperties = {
    width: 140,
  }

  const smallerBoxProperties = {
    width: 55,
    '& *': {
      p: 2,
    },
  }

  const cloudArcColorsMap: Record<AnimationStates, string> = {
    [AnimationStates.redActive]: palette.error.dark,
    [AnimationStates.greenActive]: palette.primary.main,
    [AnimationStates.idle]: palette.common.black,
  }

  const greenBoxPropertiesMap: Record<AnimationStates, SxProps> = {
    [AnimationStates.redActive]: smallerBoxProperties,
    [AnimationStates.greenActive]: biggerBoxProperties,
    [AnimationStates.idle]: {},
  }

  const redBoxPropertiesMap: Record<AnimationStates, SxProps> = {
    [AnimationStates.redActive]: biggerBoxProperties,
    [AnimationStates.greenActive]: smallerBoxProperties,
    [AnimationStates.idle]: {},
  }

  return (
    <Stack
      sx={{
        width: 241,
        height: 162,
        background: palette.common.black,
        borderRadius: 3.25,
        position: 'relative',
      }}
    >
      <Stack sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
        <UiIllustration
          name={Illustrations.ChatCloud}
          sx={{
            maxWidth: 'unset',
            maxHeight: 'unset',
            position: 'absolute',
            top: 0,
            width: '100%',
            height: 129,
            transition: Transitions.Gentle,
            color: cloudArcColorsMap[animationState],
          }}
        />
        <Stack
          sx={{
            ...baseRowStyles,
            width: 175,
            top: 28,
            left: 20,
          }}
        />
        <Stack
          sx={{
            ...baseRowStyles,
            width: 97,
            top: 48,
            left: 20,
          }}
        />
      </Stack>
      <Stack direction='row' sx={{ position: 'absolute', bottom: 1, left: 1 }}>
        <Stack
          sx={{
            ...baseBoxStyles,
            ...greenBoxPropertiesMap[animationState],
            background: palette.primary.main,
          }}
          onClick={() => updateAnimation(AnimationStates.greenActive)}
        >
          <UiIcon
            size={10}
            name={Icons.ArrowUpCircleLine}
            sx={{ transition: Transitions.Gentle, color: palette.common.white }}
          />
        </Stack>
        <Stack
          sx={{
            ...baseBoxStyles,
            ...redBoxPropertiesMap[animationState],
            background: palette.error.dark,
          }}
          onClick={() => updateAnimation(AnimationStates.redActive)}
        >
          <UiIcon
            size={10}
            name={Icons.ArrowDownCircleLine}
            sx={{ transition: Transitions.Gentle, color: palette.common.white }}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
