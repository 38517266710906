import { Stack, useTheme } from '@mui/material'
import { PropsWithChildren, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { AppHeader, AppNavbar, CongratsModal, NetworkWarningProtector } from '@/common'
import { RoutePaths } from '@/enums'
import { ErrorHandler } from '@/helpers'
import { useCollateralToken, useOrder } from '@/hooks'
import { authStore, useWeb3State } from '@/store'
import { vh } from '@/theme/helpers'

const MainLayout = ({ children }: PropsWithChildren) => {
  useCollateralToken()

  const { breakpoints, palette } = useTheme()
  const { loadPermitsAllowances } = useOrder()
  const { provider } = useWeb3State()

  const location = useLocation()
  const isMarketPage = location.pathname.includes(RoutePaths.Market)

  useEffect(() => {
    const load = async () => {
      if (!provider.address) return

      try {
        await Promise.all([authStore.loadAccountUpdates(), loadPermitsAllowances()])

        return true
      } catch (error) {
        ErrorHandler.processWithoutFeedback(error)
      }
    }

    load()
  }, [provider.address, loadPermitsAllowances])

  return (
    <Stack
      direction='row'
      height={vh(100)}
      width='100%'
      sx={{
        backgroundColor: palette.background.default,
        [breakpoints.down('md')]: {
          flexDirection: 'column',
          gap: 0,
          ...(isMarketPage && { backgroundColor: palette.background.light }),
        },
      }}
    >
      <AppNavbar
        sx={{
          [breakpoints.up('md')]: {
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100%',
          },
        }}
      />
      <AppHeader />
      <Stack flex={1} overflow='hidden auto' mt={{ xs: 15, md: 20 }}>
        <NetworkWarningProtector>{!!provider.address && children}</NetworkWarningProtector>
      </Stack>

      <CongratsModal />
    </Stack>
  )
}

export default MainLayout
