// TODO: refactor, place into service folder
export type Market = {
  id: string
  ends_at: string
  metadata: {
    title?: string
    description?: string
    logo_url?: string
    banner_url?: string
  }
  questions: Question[]
  meta: {
    total_bids: string
    total_users: number
  }
}

export type Question = {
  id: string
  metadata: {
    title: string
    logo_url?: string
    yes_label?: string
    no_label?: string
  }
  tokens: Token[]
  condition_id: string
  reported_outcome: boolean
}

export type Token = {
  id: string
  outcome: boolean
  top_ask: Bid
  top_bid: Bid
}

export type Bid = {
  price: string
  size: string
}

export enum OrderSide {
  Buy,
  Sell,
}

export const TokenOutcome = {
  No: false,
  Yes: true,
}

export enum ChartDateRange {
  Hour = '1h',
  SixHours = '6h',
  Day = '1d',
  Week = '1w',
  Month = '1m',
  All = 'all',
}

export type MarketChartDataItem = {
  price: string
  date: number
}

export type MarketOrder = {
  id: string
  size: string
  price: string
}
