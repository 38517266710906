import { api } from '@/api/clients'
import {
  type Account,
  AccountUpdate,
  AccountUpdateType,
  getAccount,
  getAccountUpdates,
} from '@/api/modules/account'
import { authorizeUser } from '@/api/modules/auth'
import { PermitTokenTypes } from '@/api/modules/order-book'
import { getBadgesList } from '@/api/modules/points'
import { createStore } from '@/helpers'
import { parseJwt } from '@/helpers/jwt'

type AuthState = {
  accessToken: string
  username: string
  referralCode: string
  emailVerificationCode: string
  hasFinishedIntro: boolean
  account: Account
  accountUpdates: AccountUpdate<AccountUpdateType>[]
  permits: Record<PermitTokenTypes, boolean>
}

const [authStore, useAuthState] = createStore(
  'auth',
  {
    accessToken: '',
    username: '',
    referralCode: '',
    emailVerificationCode: '',
    hasFinishedIntro: false,
    account: {} as Account,
    accountUpdates: [],
    permits: {} as Record<PermitTokenTypes, boolean>,
  } as AuthState,
  state => ({
    get isAuthorized() {
      return Boolean(state.accessToken)
    },
    get isLoggedIn() {
      return Boolean(state.username)
    },
  }),
  state => ({
    setPermits(value: Record<PermitTokenTypes, boolean>) {
      state.permits = value
    },
    async signIn(addr: string, signature: string) {
      const { access_token } = await authorizeUser(addr, signature)
      state.accessToken = access_token.token
      this.addAuthInterceptor()
    },
    // TODO: replace signIn and logIn with a single method
    async logIn(addr: string) {
      const { data } = await getAccount(addr)
      this.setAccount(data)
    },
    setAccount(account: Account) {
      this.setUsername(account.username)
      state.account = account
    },
    signOut() {
      state.accessToken = ''
      state.username = ''
      state.hasFinishedIntro = false
      state.account = {} as Account
      state.accountUpdates = []
      state.permits = {} as Record<PermitTokenTypes, boolean>
      api.clearInterceptors()
    },
    // TODO: implement auth
    verifyToken(address: string) {
      const parsedJwt = parseJwt(state.accessToken)
      if (
        !parsedJwt ||
        parsedJwt.exp < Date.now() / 1000 ||
        parsedJwt.sub.toLowerCase() !== address.toLowerCase()
      ) {
        this.signOut()
      }
      this.addAuthInterceptor()
    },
    async updateHasFinishedIntro(address: string) {
      // TODO: check after permits impl
      const { data } = await getBadgesList(address, {
        query: { 'page[limit]': 100 },
      })

      this.setHasFinishedIntro(data.some(badge => badge.gained))
    },
    addAuthInterceptor() {
      api.addInterceptor({
        request: async config => {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${state.accessToken}`,
          }
          return config
        },
        response: async response => {
          if (response.status === 401) {
            this.signOut()
            window.location.reload()
          }
          return response
        },
      })
    },
    setHasFinishedIntro(value: boolean) {
      state.hasFinishedIntro = value
    },
    setUsername(value: string) {
      state.username = value
    },
    setReferralCode(value: string) {
      state.referralCode = value
    },
    setEmailVerificationCode(value: string) {
      state.emailVerificationCode = value
    },
    async loadAccountUpdates() {
      if (!state.account.username) return

      const { data } = await getAccountUpdates(state.account.id)

      state.accountUpdates = data
    },
  }),
)

export { authStore, useAuthState }
