import { api } from '@/api/clients'
import { PermitTokenTypes } from '@/api/modules/order-book/types'
import { ApiServicePaths } from '@/enums'

export type PermitItem = { token_type: PermitTokenTypes; signature: string; nonce: string }

export const getPermits = async (address: string) => {
  return api.get<{
    id: string
    type: 'permits'

    collateral_exchange_permit: boolean
    collateral_adapter_permit: boolean
    conditional_exchange_permit: boolean
    conditional_adapter_permit: boolean
  }>(`${ApiServicePaths.OrderBook}/v1/public/permits/${address}`)
}

export const createPermits = async (address: string, permits: PermitItem[]) => {
  return api.post(`${ApiServicePaths.OrderBook}/v1/public/permits/${address}`, {
    body: {
      data: permits.map(el => ({
        type: 'permits',
        attributes: {
          ...el,
        },
      })),
    },
  })
}
