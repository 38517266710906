import '@/locales'
// eslint-disable-next-line import/no-unresolved
import 'virtual:svg-icons-register'

import { config } from '@config'
import * as Sentry from '@sentry/react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'

import App from './App'

if (config.GTM_ID) {
  try {
    TagManager.initialize({
      gtmId: config.GTM_ID,
    })
  } catch {
    /* empty */
  }
}

if (config.SENTRY_DSN) {
  try {
    Sentry.init({
      dsn: config.SENTRY_DSN,
      integrations: [
        Sentry.captureConsoleIntegration(),
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.extraErrorDataIntegration(),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets'
      // to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', window.location.origin],
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })
  } catch {
    /* empty */
  }
}

const root = createRoot(document.getElementById('root') as Element)

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)
