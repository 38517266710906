import { config } from '@config'
import { BN } from '@distributedlab/tools'
import { constants, utils } from 'ethers'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { createPermits, getPermits, PermitTokenTypes, placeOrder } from '@/api/modules/order-book'
import { ZERO_BN } from '@/constants'
import { SignatureTypes } from '@/enums'
import { ErrorHandler, sanitizeNumberString, sleep } from '@/helpers'
import { useOrderEip712 } from '@/hooks/order-eip712'
import { useCollateralEip712, useConditionalEip712 } from '@/hooks/tokens-eip712'
import { authStore, useWeb3State } from '@/store'
import { type MarketOrder, OrderSide } from '@/types'
import { OrderStruct } from '@/types/contracts/NegRiskCtfExchange'

const MAX_TIMESTAMP_VALUE = (Math.pow(2, 32) - 1).toString()

export const useOrder = (opts?: {
  isFirst?: boolean
}): {
  loadPermitsAllowances: () => Promise<Record<PermitTokenTypes, boolean>>
  order: Partial<OrderStruct>
  createOrder: ({
    tokenId,
    makerAmount,
    takerAmount,
  }: {
    tokenId: string
    makerAmount: string
    takerAmount: string
  }) => Partial<OrderStruct>
  submitOrder: (orderBook: MarketOrder[], tokenId: string, shares: string) => Promise<void>
  getPriceDetails: (
    orderBook: MarketOrder[],
    shares: string,
  ) => { size: BN; averagePrice: BN; bestPrice: BN }
} => {
  const [order, setOrder] = useState<Partial<OrderStruct>>({
    taker: constants.AddressZero,
    expiration: MAX_TIMESTAMP_VALUE,
    feeRateBps: '0',
    signatureType: 0,
  })
  const { provider, isConnected, address: providerAddress, providerType } = useWeb3State()
  const { t } = useTranslation()
  const { signTypedData: orderSignTypedData, getNonce: orderGetNonce } = useOrderEip712()

  const { signTypedData: collateralSignTypedData, getNonce: getCollateralNonce } =
    useCollateralEip712()
  const { signTypedData: conditionalSignTypedData, getNonce: getConditionalNonce } =
    useConditionalEip712()

  const loadPermitsAllowances = useCallback(async () => {
    if (!provider.address) throw new ReferenceError('No provider address')

    const result = {
      [PermitTokenTypes.CollateralExchange]: false,
      [PermitTokenTypes.CollateralAdapter]: false,
      [PermitTokenTypes.ConditionalExchange]: false,
      [PermitTokenTypes.ConditionalAdapter]: false,
    }

    try {
      const { data } = await getPermits(provider.address)

      result[PermitTokenTypes.CollateralExchange] = data.collateral_exchange_permit
      result[PermitTokenTypes.CollateralAdapter] = data.collateral_adapter_permit
      result[PermitTokenTypes.ConditionalExchange] = data.conditional_exchange_permit
      result[PermitTokenTypes.ConditionalAdapter] = data.conditional_adapter_permit
    } catch (error) {
      ErrorHandler.processWithoutFeedback(error)
    }

    authStore.setPermits(result)

    return result
  }, [provider.address])

  const createOrder = useCallback(
    ({
      tokenId,
      makerAmount,
      takerAmount,
    }: {
      tokenId: string
      makerAmount: string
      takerAmount: string
    }) => {
      // TODO: validate fields
      return {
        ...order,
        salt: utils.hexlify(utils.randomBytes(32)),
        tokenId,
        makerAmount,
        takerAmount,
        side: OrderSide.Buy,
      }
    },
    [order],
  )

  const getSortedOrderBook = useCallback(
    (orderBook: MarketOrder[]) =>
      orderBook.sort((a, b) => BN.fromBigInt(a.price).cmp(BN.fromBigInt(b.price))),
    [],
  )

  const getPriceDetails = useCallback(
    (
      orderBook: MarketOrder[],
      sharesRaw: string,
      isFirst = false,
    ): { size: BN; averagePrice: BN; bestPrice: BN; isFirst?: boolean } => {
      let sortedOrderBook = getSortedOrderBook(orderBook)

      // For the first order we are using more expensive positions to decrease chances
      // of same position being filled by other users
      if (isFirst && sortedOrderBook.length > 2) {
        sortedOrderBook = sortedOrderBook.slice(2)
      }

      let totalPrice = BN.fromRaw('0')
      let totalSize = BN.fromRaw('0')
      let bestPrice = BN.fromRaw('0')

      let inputAmount = BN.fromRaw(sanitizeNumberString(sharesRaw) || '0')

      if (!sortedOrderBook.length || inputAmount.eq(ZERO_BN))
        return {
          size: totalSize,
          averagePrice: totalPrice,
          bestPrice,
        }
      for (const position of sortedOrderBook) {
        const sizeBn = BN.fromBigInt(position.size)
        const priceBn = BN.fromBigInt(position.price)
        if (sizeBn.eq(ZERO_BN)) continue
        bestPrice = priceBn
        if (priceBn.mul(sizeBn).gt(inputAmount)) {
          const lastSize = priceBn.isZero ? ZERO_BN : inputAmount.div(priceBn)
          totalPrice = totalPrice.add(lastSize.mul(priceBn))
          totalSize = totalSize.add(lastSize)
          break
        }
        if (priceBn.mul(sizeBn).lte(inputAmount)) {
          totalPrice = totalPrice.add(priceBn.mul(sizeBn))
          totalSize = totalSize.add(sizeBn)
          inputAmount = inputAmount.sub(priceBn.mul(sizeBn))
        }
      }
      return {
        size: totalSize,
        averagePrice: totalSize.isZero ? ZERO_BN : totalPrice.div(totalSize),
        bestPrice,
      }
    },
    [getSortedOrderBook],
  )

  const getCollateralExchangePermitSig = useCallback(
    async (ownerAddress: string, nonce: number) => {
      return await collateralSignTypedData(
        ownerAddress,
        config.CTF_EXCHANGE_CONTRACT,
        BN.MAX_UINT256.value,
        nonce,
        BN.MAX_UINT256.value,
      )
    },
    [collateralSignTypedData],
  )

  const getCollateralAdapterPermitSig = useCallback(
    async (ownerAddress: string, nonce: number) => {
      return await collateralSignTypedData(
        ownerAddress,
        config.ADAPTER_CONTRACT,
        BN.MAX_UINT256.value,
        nonce,
        BN.MAX_UINT256.value,
      )
    },
    [collateralSignTypedData],
  )

  const getConditionalExchangePermitSig = useCallback(
    async (ownerAddress: string, nonce: number) => {
      return await conditionalSignTypedData(
        ownerAddress,
        config.CTF_EXCHANGE_CONTRACT,
        nonce,
        BN.MAX_UINT256.value,
      )
    },
    [conditionalSignTypedData],
  )

  const getConditionalAdapterPermitSig = useCallback(
    async (ownerAddress: string, nonce: number) => {
      return await conditionalSignTypedData(
        ownerAddress,
        config.ADAPTER_CONTRACT,
        nonce,
        BN.MAX_UINT256.value,
      )
    },
    [conditionalSignTypedData],
  )

  const ensurePermits = useCallback(async () => {
    if (!provider.address) throw new TypeError('No provider address')

    let permitsResult: Awaited<ReturnType<typeof loadPermitsAllowances>>

    let collateralExchangeSig = ''
    let collateralAdapterSig = ''
    let conditionalExchangeSig = ''
    let conditionalAdapterSig = ''

    do {
      permitsResult = await loadPermitsAllowances()

      let collateralNonce = (await getCollateralNonce(provider.address)).toNumber()

      let conditionalNonce = (await getConditionalNonce(provider.address)).toNumber()

      if (!permitsResult[PermitTokenTypes.CollateralExchange] && !collateralExchangeSig) {
        collateralExchangeSig =
          (await getCollateralExchangePermitSig(provider.address, collateralNonce)) ?? ''

        if (!collateralExchangeSig)
          throw new ReferenceError('No collateral exchange permit signature')

        await createPermits(provider.address, [
          {
            token_type: PermitTokenTypes.CollateralExchange,
            signature: collateralExchangeSig,
            nonce: collateralNonce.toString(),
          },
        ])

        collateralNonce += 1
      }

      if (!permitsResult[PermitTokenTypes.ConditionalExchange] && !conditionalExchangeSig) {
        conditionalExchangeSig =
          (await getConditionalExchangePermitSig(provider.address, conditionalNonce)) ?? ''

        if (!conditionalExchangeSig)
          throw new ReferenceError('No conditional exchange permit signature')

        await createPermits(provider.address, [
          {
            token_type: PermitTokenTypes.ConditionalExchange,
            signature: conditionalExchangeSig,
            nonce: conditionalNonce.toString(),
          },
        ])

        conditionalNonce += 1
      }

      if (!permitsResult[PermitTokenTypes.CollateralAdapter] && !collateralAdapterSig) {
        collateralAdapterSig =
          (await getCollateralAdapterPermitSig(provider.address, conditionalNonce)) ?? ''

        if (!collateralAdapterSig)
          throw new ReferenceError('No collateral adapter permit signature')

        await createPermits(provider.address, [
          {
            token_type: PermitTokenTypes.CollateralAdapter,
            signature: collateralAdapterSig,
            nonce: String(conditionalNonce),
          },
        ])
      }

      if (!permitsResult[PermitTokenTypes.ConditionalAdapter] && !conditionalAdapterSig) {
        conditionalAdapterSig =
          (await getConditionalAdapterPermitSig(provider.address, conditionalNonce)) ?? ''

        if (!conditionalAdapterSig)
          throw new ReferenceError('No conditional adapter permit signature')

        await createPermits(provider.address, [
          {
            token_type: PermitTokenTypes.ConditionalAdapter,
            signature: conditionalAdapterSig,
            nonce: String(conditionalNonce),
          },
        ])
      }

      await sleep(1_000)
    } while (
      !permitsResult[PermitTokenTypes.CollateralAdapter] ||
      !permitsResult[PermitTokenTypes.CollateralExchange] ||
      !permitsResult[PermitTokenTypes.ConditionalAdapter] ||
      !permitsResult[PermitTokenTypes.ConditionalExchange]
    )
  }, [
    getCollateralAdapterPermitSig,
    getCollateralExchangePermitSig,
    getCollateralNonce,
    getConditionalAdapterPermitSig,
    getConditionalExchangePermitSig,
    getConditionalNonce,
    loadPermitsAllowances,
    provider.address,
  ])

  const submitOrder = useCallback(
    async (orderBook: MarketOrder[], tokenId: string, sharesRaw: string) => {
      if (!provider.address) throw new ReferenceError(t('errors.no-provider-address'))

      await ensurePermits()

      const priceDetails = getPriceDetails(orderBook, sharesRaw, opts?.isFirst)

      const makerAmount = BN.fromRaw(sharesRaw)
      const takerAmount = priceDetails.bestPrice.isZero
        ? ZERO_BN
        : makerAmount.div(priceDetails.bestPrice)

      const order = createOrder({
        tokenId: tokenId || '',
        makerAmount: makerAmount.value,
        takerAmount: takerAmount.value,
      }) as Required<OrderStruct>

      const updatedOrder = {
        ...order,
        nonce: (await orderGetNonce(provider.address)).toNumber(),
        signatureType: SignatureTypes.InjectedAcc,
      }

      const signedOrder = await orderSignTypedData(updatedOrder)

      if (!signedOrder) throw new ReferenceError(t('errors.no-signed-order'))

      await placeOrder({ ...updatedOrder, signature: signedOrder }, opts?.isFirst)
    },
    [
      provider?.address,
      t,
      ensurePermits,
      getPriceDetails,
      createOrder,
      orderGetNonce,
      orderSignTypedData,
      opts?.isFirst,
    ],
  )

  useEffect(() => {
    const makerAddress = providerAddress
    const signerAddress = providerAddress

    const isSameAddress = order.maker === makerAddress && order.signer === signerAddress

    if (!isConnected || !(makerAddress && signerAddress) || isSameAddress) return
    setOrder({
      ...order,
      maker: makerAddress,
      signer: signerAddress,
    })
  }, [isConnected, providerAddress, order, providerType])

  return {
    loadPermitsAllowances,
    order,
    createOrder,
    submitOrder,
    getPriceDetails,
  }
}
