import { api } from '@/api/clients'
import { ApiServicePaths } from '@/enums'
import { OrderStruct } from '@/types/contracts/NegRiskCtfExchange'

export const placeOrder = async (order: OrderStruct, isFirst?: boolean) => {
  const endpoint = isFirst
    ? `${ApiServicePaths.OrderBook}/v1/public/orders/first`
    : `${ApiServicePaths.OrderBook}/v1/public/orders`

  const { data } = await api.post<OrderStruct>(endpoint, {
    body: {
      data: {
        type: 'orders',
        attributes: {
          salt: order.salt,
          maker: order.maker,
          signer: order.signer,
          taker: order.taker,
          token_id: order.tokenId,
          maker_amount: order.makerAmount,
          taker_amount: order.takerAmount,
          expiration: order.expiration,
          nonce: order.nonce,
          fee_rate_bps: order.feeRateBps,
          side: order.side,
          signature: order.signature,
          signature_type: order.signatureType,
        },
      },
    },
  })
  return data
}
