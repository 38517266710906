import 'aos/dist/aos.css'

import { CssBaseline, ThemeProvider } from '@mui/material'
import AOS from 'aos'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { RouterProvider } from 'react-router-dom'

import { AppLoader, ErrorBoundaryFallback } from '@/common'
import { ToastsManager } from '@/contexts'
import { useSystemPaletteMode, useViewportSizes } from '@/hooks'
import { useLocalisedYupScheme } from '@/hooks/yup'
import { createRouter } from '@/routes'
import { authStore, useWeb3State, web3Store } from '@/store'
import { createTheme } from '@/theme'

const router = createRouter()

const App = () => {
  const [isAppInitialized, setIsAppInitialized] = useState(false)

  const { address, providerType, isConnected } = useWeb3State()
  const paletteMode = useSystemPaletteMode()

  useViewportSizes()
  useLocalisedYupScheme()

  const initWeb3 = useCallback(async () => {
    try {
      await web3Store.init()
    } catch {
      // TODO: fix web3 store
    }
  }, [])

  const init = useCallback(async () => {
    try {
      AOS.init({
        duration: 1000,
        easing: 'ease',
        offset: 0,
        once: true,
        mirror: false,
        anchorPlacement: 'center-bottom',
      })

      if (providerType) await initWeb3()
    } catch {
      // TODO: fix web3 store
    }
    setIsAppInitialized(true)
  }, [providerType, initWeb3])

  const theme = useMemo(() => createTheme(paletteMode), [paletteMode])

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if ((!address && isConnected) || !isConnected) return
    authStore.verifyToken(address || '')
  }, [address, isConnected])

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Helmet>
          <meta name='theme-color' content={theme.palette.background.default} />
        </Helmet>
        <CssBaseline />
        <ToastsManager>
          <div className='App' key='app_main'>
            {isAppInitialized ? (
              <ErrorBoundary
                FallbackComponent={({ resetErrorBoundary }) => (
                  <ErrorBoundaryFallback onReset={resetErrorBoundary} />
                )}
                onReset={() => window.location.reload()}
              >
                <RouterProvider router={router} />
              </ErrorBoundary>
            ) : (
              <AppLoader />
            )}
          </div>
        </ToastsManager>
      </ThemeProvider>
    </HelmetProvider>
  )
}

export default memo(App)
