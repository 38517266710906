import { Box, Stack, StackProps, useTheme } from '@mui/material'

import { Icons } from '@/enums'
import { Transitions } from '@/theme/constants'
import { UiIcon } from '@/ui'

export default function DailyMarketAnimation() {
  const { palette } = useTheme()

  return (
    <Box
      sx={theme => ({
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        background: theme.palette.common.black,
        borderRadius: 4,
        maxWidth: 166,
      })}
    >
      <IconWrapper
        sx={{
          background: palette.primary.main,
          '&:hover': {
            transform: 'rotate(-12deg) translate(-10px, -10px)',
            '& svg': {
              transform: 'rotate(-360deg)',
            },
          },
        }}
      >
        <UiIcon name={Icons.Ion} size={10} color={palette.common.white} />
      </IconWrapper>
      <IconWrapper
        sx={{
          background: palette.inverted.light,
          '&:hover': {
            transform: 'rotate(12deg) translate(10px, -10px)',
            '& svg': {
              transform: 'rotate(-360deg)',
            },
          },
        }}
      >
        <UiIcon name={Icons.StarTwo} size={20} color={palette.secondary.main} />
      </IconWrapper>
      <IconWrapper
        sx={{
          background: palette.inverted.light,
          '&:hover': {
            transform: 'rotate(-12deg) translate(-10px, 10px)',
            '& svg': {
              transform: 'rotate(-360deg)',
            },
          },
        }}
      >
        <UiIcon name={Icons.StarThree} size={20} color={palette.secondary.main} />
      </IconWrapper>
      <IconWrapper
        sx={{
          background: palette.primary.dark,
          '&:hover': {
            transform: 'rotate(12deg) translate(10px, 10px)',
            '& svg': {
              transform: 'rotate(-360deg)',
            },
          },
        }}
      >
        <UiIcon name={Icons.CoinLine} size={20} px={1} color={palette.common.white} />
      </IconWrapper>
    </Box>
  )
}

const IconWrapper = ({ sx, children }: StackProps) => {
  const { palette } = useTheme()

  return (
    <Stack
      sx={{
        borderRadius: 4,
        border: `${palette.common.black} 1px solid`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: Transitions.Gentle,
        '& svg': {
          transition: Transitions.Gentle,
        },
        ...sx,
      }}
    >
      {children}
    </Stack>
  )
}
