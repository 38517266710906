import { fetcher } from '@distributedlab/fetcher'
import { JsonApiClientRequestOpts } from '@distributedlab/jac'

import { api } from '@/api/clients'
import {
  Account,
  AccountActivity,
  AccountUpdate,
  AccountUpdateType,
  CreateAccountDetails,
  UpdateAccountDetails,
} from '@/api/modules/account/types'
import { ApiServicePaths } from '@/enums'
import { Position } from '@/types'

export const getActivities = async (address: string, opts?: Partial<JsonApiClientRequestOpts>) => {
  return api.get<AccountActivity[]>(
    `${ApiServicePaths.OrderBook}/v1/public/${address}/activities`,
    opts,
  )
}

export const getPositions = async (address: string, opts?: Partial<JsonApiClientRequestOpts>) => {
  return api.get<Position[]>(`${ApiServicePaths.OrderBook}/v1/public/${address}/positions`, opts)
}

export const getAccount = async (address: string, opts?: Partial<JsonApiClientRequestOpts>) => {
  return api.get<Account>(`${ApiServicePaths.Points}/v1/public/accounts/${address}`, opts)
}

export const getAccountUpdates = async (
  address: string,
  opts?: Partial<JsonApiClientRequestOpts>,
) => {
  return api.get<AccountUpdate<AccountUpdateType>[]>(
    `${ApiServicePaths.Points}/v1/public/accounts/${address}/updates`,
    opts,
  )
}

export const markAccountUpdateAsRead = async (address: string, updateId: string) => {
  return api.patch(`${ApiServicePaths.Points}/v1/public/accounts/${address}/updates/${updateId}`)
}

export const checkAccountExistenceNoConflicts = async (username: string, email?: string) => {
  return api.post(`${ApiServicePaths.Points}/v1/public/check-conflicts`, {
    body: {
      data: {
        type: 'check_conflicts',
        attributes: {
          username: username,
          ...(email && { email }),
        },
      },
    },
  })
}

export const createAccount = (accountData: CreateAccountDetails) => {
  return api.post<Account>(`${ApiServicePaths.Points}/v1/public/accounts`, {
    body: {
      data: {
        id: accountData.address,
        type: 'accounts',
        attributes: {
          username: accountData.username,
          ...(accountData.referral && { referred_by: accountData.referral }),
          ...(accountData.email && { email: accountData.email }),
          ...(accountData.profileImg && { profile_photo: accountData.profileImg }),
        },
      },
    },
  })
}

export const getAvatarUploadUrl = async ({
  address,
  contentType,
  fileSize,
}: {
  contentType: string
  fileSize: string
  address: string
}) => {
  return api.get<{ url: string }>(
    `${ApiServicePaths.Points}/v1/public/accounts/${address}/profile_photo`,
    {
      query: {
        content_type: contentType,
        content_length: fileSize,
      },
    },
  )
}

export const uploadAvatar = async (avatar: File | null, address: string) => {
  if (!avatar) return ''

  const { data } = await getAvatarUploadUrl({
    address: address || '',
    contentType: avatar.type,
    fileSize: avatar.size.toString(),
  })

  await fetcher.put(data.url, {
    body: avatar,
    headers: {
      'Content-Type': avatar.type,
      'x-amz-acl': 'public-read',
    },
  })

  const url = new URL(data.url)
  return url.origin + url.pathname
}

export const verifyEmail = async (code: string) => {
  return api.post(`${ApiServicePaths.Points}/v1/public/verify_email`, {
    query: {
      code,
    },
  })
}
export const updateAccountDetails = async (address: string, accountData: UpdateAccountDetails) => {
  return api.patch<Account>(`${ApiServicePaths.Points}/v1/public/accounts/${address}`, {
    body: {
      data: {
        id: address,
        type: 'accounts',
        attributes: {
          ...(accountData.email && { email: accountData.email }),
          ...(accountData.profileImg && { profile_photo: accountData.profileImg }),
        },
      },
    },
  })
}
export const sendVerificationEmail = async (address: string) => {
  return api.get(`${ApiServicePaths.Points}/v1/public/accounts/${address}/verify_email`)
}

export const requestVerificationEmail = async (
  address: string,
  accountData: UpdateAccountDetails,
) => {
  await updateAccountDetails(address, accountData)
  await sendVerificationEmail(address)
}
