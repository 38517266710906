import { Box, Button, Link, Stack, Typography, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import { Icons } from '@/enums'
import { UiIcon } from '@/ui'

export default function PromoSection() {
  const { t } = useTranslation()

  return (
    <Stack
      component='section'
      data-aos='fade-up'
      data-aos-anchor-placement='center-bottom'
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        py: { xs: 10, md: 30 },
        gap: 10,
      }}
    >
      <Typography
        variant='subtitle3'
        textAlign='center'
        sx={theme => ({ color: theme.palette.text.secondary, mx: 'auto', maxWidth: 400 })}
      >
        {t('home-page.promo-subtitle')}
      </Typography>
      <PromoTitle />
      <Stack spacing={4}>
        <Button
          variant='outlined'
          color='primary'
          size='large'
          component={Link}
          href='https://forms.gle/txtt6aRVKRUKiyP29'
          target='_blank'
          rel='noreferrer'
          sx={{
            height: 56,
            gap: 2,
            px: 8,
            py: 4.5,
            width: 'fit-content',
            mx: 'auto',
          }}
        >
          {t('home-page.promo-btn')}
        </Button>
        <Typography
          variant='body3'
          textAlign='center'
          color={theme => ({ color: theme.palette.text.secondary })}
        >
          {t('home-page.promo-description')}
        </Typography>
      </Stack>
    </Stack>
  )
}

const PromoTitle = () => {
  const { palette, typography } = useTheme()

  return (
    <Typography
      textAlign='center'
      maxWidth={820}
      sx={{
        typography: {
          xs: typography.h2,
          md: typography.h1,
        },
        userSelect: 'none',
        color: palette.text.primary,
      }}
    >
      <Trans
        i18nKey='home-page.promo-title'
        components={{
          PrimaryText: <Box component='span' sx={{ color: palette.primary.main }} />,
          BlobBadge: (
            <UiIcon
              name={Icons.BlobBadge}
              size={10}
              sx={{
                color: palette.primary.main,
                position: 'relative',
                top: 4,
              }}
            />
          ),
          SecondaryText: <Box component='span' sx={{ color: palette.secondary.main }} />,
          HalfCircleBadge: (
            <UiIcon
              name={Icons.HalfCircleBadge}
              size={10}
              sx={{
                color: palette.secondary.main,
                position: 'relative',
                height: 20,
                minHeight: 20,
                maxHeight: 20,
                top: 0,
                left: 0,
              }}
            />
          ),
        }}
      />
    </Typography>
  )
}
