import {
  Box,
  Button,
  Dialog,
  DialogProps,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Icons } from '@/enums'
import { UiDialogContent, UiIcon } from '@/ui'

type Props = DialogProps

export default function BrowserNotSupportedModal({ ...rest }: Props) {
  const { palette, spacing } = useTheme()
  const { t } = useTranslation()

  return (
    <Dialog
      {...rest}
      PaperProps={{
        noValidate: true,
        position: 'relative',
        ...rest.PaperProps,
        sx: { width: 470, ...rest.PaperProps?.sx },
      }}
    >
      <IconButton
        onClick={e => rest?.onClose?.(e, 'backdropClick')}
        aria-label='close'
        sx={{
          position: 'absolute',
          top: spacing(6),
          right: spacing(6),
        }}
      >
        <UiIcon name={Icons.Close} size={5} />
      </IconButton>

      <UiDialogContent
        display='flex'
        flexDirection='column'
        alignItems='center'
        gap={6}
        px={6}
        py={8}
        textAlign='center'
      >
        <Box
          component='img'
          src='/imgs/thoughtful-quokka.webp'
          height={112}
          sx={{ objectFit: 'cover' }}
        />

        <Stack spacing={2} alignItems='center'>
          <Typography variant='subtitle2'>{t('browser-not-supported-modal.title')}</Typography>
          <Typography variant='body3' color={palette.text.secondary} maxWidth={360}>
            {t('browser-not-supported-modal.description')}
          </Typography>
        </Stack>

        <Button variant='outlined' onClick={e => rest?.onClose?.(e, 'backdropClick')} fullWidth>
          {t('browser-not-supported-modal.submit-lbl')}
        </Button>
      </UiDialogContent>
    </Dialog>
  )
}
