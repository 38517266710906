import { Box, Stack, SxProps, Theme, useTheme } from '@mui/material'
import { useMemo, useState } from 'react'

import { Icons, Illustrations } from '@/enums'
import usePressedState from '@/hooks/press-action'
import { Transitions } from '@/theme/constants'
import { UiIcon } from '@/ui'
import UiIllustration from '@/ui/UiIllustration'

enum KeyIds {
  Chart = 'chart',
  Person = 'person',
  Chat = 'chat',
  Enter = 'enter',
}

export default function CommentsAnimation() {
  const { palette } = useTheme()
  const [activeId, setActiveId] = useState(KeyIds.Chart)

  const changeActiveKey = (id: KeyIds) => {
    if (id === activeId) {
      const keyArray = Object.values(KeyIds)
      const newActiveIdx = (keyArray.indexOf(id) + 1) % keyArray.length
      setActiveId(keyArray[newActiveIdx])
    }
  }

  const smallKeys = useMemo(
    () => [
      {
        iconName: Icons.PieChartLine,
        coordinates: { bottom: 0, right: 173 },
        colors: {
          top: palette.primary.main,
          base: palette.primary.darker,
          icon: palette.common.white,
        },
        id: KeyIds.Chart,
      },
      {
        iconName: Icons.UserThree,
        coordinates: { bottom: 78, right: 150 },
        colors: {
          top: palette.info.main,
          base: palette.info.darker,
          icon: palette.common.black,
        },
        id: KeyIds.Person,
      },
      {
        iconName: Icons.WeChatLine,
        coordinates: { bottom: 0, right: 98, zIndex: 2 },
        colors: {
          top: palette.success.main,
          base: palette.success.darker,
          icon: palette.common.black,
        },
        id: KeyIds.Chat,
      },
    ],
    [palette],
  )

  return (
    <Stack sx={{ maxWidth: 256, height: 173, position: 'relative', justifyContent: 'flex-end' }}>
      {smallKeys.map(item => (
        <SmallKey
          {...item}
          isActive={activeId === item.id}
          onRelease={() => changeActiveKey(item.id)}
          key={item.id}
        />
      ))}
      <EnterKey
        isActive={activeId === KeyIds.Enter}
        onRelease={() => changeActiveKey(KeyIds.Enter)}
      />
      <BaseSurface />
    </Stack>
  )
}

type SmallKeyProps = {
  coordinates: SxProps<Theme>
  iconName: Icons
  colors: { top: string; base?: string; icon: string }
} & KeyProps

type KeyProps = {
  isActive: boolean
  onRelease: () => void
}

const SmallKey = (props: SmallKeyProps) => {
  const { iconName, coordinates, isActive, onRelease, colors } = props
  const { isPressed, press, release } = usePressedState({ onRelease })
  const { palette } = useTheme()

  const baseBoxStyles = {
    background: palette.common.black,
    transition: Transitions.Fast,
    borderRadius: 1,
    bottom: 8,
    position: 'absolute',
    height: '1px',
  }

  return (
    <Stack
      sx={{ position: 'absolute', cursor: 'pointer', width: 75, height: 80, ...coordinates }}
      onMouseUp={release}
      onMouseDown={press}
      onTouchStart={press}
      onTouchEnd={release}
      onContextMenu={event => event.preventDefault()}
    >
      <Stack
        sx={{
          height: 67,
          width: 64,
          background: isActive ? colors.top : palette.common.white,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 2.5,
          border: `${palette.common.black} 1px solid`,
          position: 'absolute',
          left: 5.5,
          zIndex: 2,
          transition: Transitions.Fast,
          transform: isPressed ? 'translateY(3px)' : 'translateY(0)',
          bottom: 12,
        }}
      >
        <UiIcon name={iconName} size={6} color={isActive ? colors.icon : palette.common.black} />
      </Stack>
      <Stack
        direction='row'
        sx={{
          width: 75,
          height: 75,
          // TODO: raw color, missing in design system
          background: isActive ? colors.base : '#F7F7F6',
          borderTopRightRadius: 11,
          borderTopLeftRadius: 11,
          borderBottomRightRadius: 13,
          borderBottomLeftRadius: 13,
          border: `${palette.common.black} 1px solid`,
          position: 'absolute',
          zIndex: 1,
          bottom: 0,
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          transition: Transitions.Fast,
        }}
      >
        <Box
          sx={{
            ...baseBoxStyles,
            left: 5,
            width: isPressed ? 2.83 : 3.51,
            transform: `rotate(-45deg) ${isPressed ? 'translate(-2px, 1px)' : 'translate(0, 0)'}`,
          }}
        />
        <Box
          sx={{
            ...baseBoxStyles,
            right: 5,
            width: isPressed ? 4.22 : 3.51,
            transform: `rotate(45deg) ${isPressed ? 'translate(2px, 1px)' : 'translate(0, 0)'}`,
          }}
        />
      </Stack>
    </Stack>
  )
}

const EnterKey = ({ isActive, onRelease }: KeyProps) => {
  const { palette } = useTheme()
  const { isPressed, press, release } = usePressedState({ onRelease })

  return (
    <Stack
      sx={{ position: 'absolute', cursor: 'pointer', top: 0, right: 0, width: 158, height: 173 }}
      onMouseDown={press}
      onMouseUp={release}
      onTouchStart={press}
      onTouchEnd={release}
      onContextMenu={event => event.preventDefault()}
    >
      <UiIllustration
        name={Illustrations.EnterBase}
        sx={{
          transition: Transitions.Fast,
          // TODO: raw color, missing in design system
          color: isActive ? palette.secondary.darker : '#F7F7F6',
          position: 'absolute',
          maxWidth: 'unset',
          maxHeight: 'unset',
          height: 156,
          width: 150,
          bottom: 0,
          right: 0,
        }}
      />
      <UiIllustration
        name={Illustrations.EnterTop}
        sx={{
          position: 'absolute',
          bottom: 7,
          right: 4,
          color: isActive ? palette.secondary.main : palette.common.white,
          transition: Transitions.Fast,
          transform: isPressed ? 'translate(-3px, 3px)' : 'translate(0px, 0px)',
          maxWidth: 'unset',
          maxHeight: 'unset',
          height: 156,
          width: 135,
        }}
      />
    </Stack>
  )
}

const BaseSurface = () => {
  return (
    <Stack
      sx={{
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
      }}
    >
      <Box sx={theme => ({ width: 225, height: 51, background: theme.palette.common.black })} />
      <Box
        sx={theme => ({
          width: 237,
          height: 69,
          borderRadius: 5,
          background: theme.palette.common.black,
        })}
      />
    </Stack>
  )
}
