import type { Provider } from '@ethersproject/providers'
import { BigNumber, type CallOverrides, Signer, TypedDataDomain, TypedDataField } from 'ethers'
import { t } from 'i18next'
import { useCallback, useMemo } from 'react'

import { useWeb3State } from '@/store'

type AbstractFactoryClass = {
  connect: (
    address: string,
    provider: Signer | Provider,
  ) => {
    nonces(owner: string, overrides?: CallOverrides): Promise<BigNumber>
    eip712Domain(overrides?: CallOverrides): Promise<
      [string, string, string, BigNumber, string, string, BigNumber[]] & {
        fields: string
        name: string
        version: string
        chainId: BigNumber
        verifyingContract: string
        salt: string
        extensions: BigNumber[]
      }
    >
  }
  createInterface: () => unknown
}

export const useEip712 = <F extends AbstractFactoryClass>(
  address: string,
  contractFactoryClass: F,
  dataType: Record<string, TypedDataField[]>,
): {
  contractInstance: ReturnType<F['connect']> | null
  signTypedData: (dataObj: Record<string, unknown>) => Promise<string>
  getNonce: (address: string) => Promise<BigNumber>
} => {
  const { contractConnector, rawProviderSigner } = useWeb3State()

  const contractInstance = useMemo(() => {
    if (!address || !contractConnector) return null

    return contractFactoryClass.connect(address, contractConnector)
  }, [address, contractConnector, contractFactoryClass])

  const getNonce = useCallback(
    (address: string) => {
      if (!contractInstance) throw new ReferenceError('Contract not found')

      return contractInstance.nonces(address)
    },
    [contractInstance],
  )

  const getDomain = useCallback(async (): Promise<Partial<TypedDataDomain>> => {
    if (!contractInstance) throw new ReferenceError('Contract not found')

    const { fields, name, version, chainId, verifyingContract, salt, extensions } =
      await contractInstance.eip712Domain()

    if (extensions.length > 0) {
      throw TypeError(t('errors.eip-712-extensions-not-supported'))
    }

    const domain: TypedDataDomain = {
      name,
      version,
      chainId: BigNumber.from(chainId).toString(),
      verifyingContract,
      salt,
    }

    Object.keys(domain).forEach((key, i) => {
      // 'fields' is a bitmask, so we iterate over the domainFieldNames and check if the bit is set
      if (!(Number(fields) & (1 << i))) {
        delete domain[key as keyof TypedDataDomain]
      }
    })

    return domain
  }, [contractInstance])

  const signTypedData = useCallback(
    async (dataObj: Record<string, unknown>) => {
      if (!rawProviderSigner || !contractInstance)
        throw new ReferenceError('Provider or contract not found')

      const domain = await getDomain()

      return rawProviderSigner._signTypedData(domain, dataType, dataObj)
    },
    [rawProviderSigner, contractInstance, getDomain, dataType],
  )

  return {
    contractInstance: contractInstance as ReturnType<F['connect']>,
    signTypedData,
    getNonce,
  }
}
