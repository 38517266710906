import { Stack, StackProps, useTheme } from '@mui/material'
import { useLocation } from 'react-router-dom'

import { RoutePaths } from '@/enums'

export default function UiContainer(props: StackProps) {
  const { breakpoints } = useTheme()

  const location = useLocation()
  const isMarketPage = location.pathname === RoutePaths.Market
  return (
    <Stack
      {...props}
      sx={{
        px: 4,
        maxWidth: 1192,
        width: '100%',
        mx: 'auto',
        [breakpoints.down('md')]: { px: isMarketPage ? 0 : 4, py: 4, pb: 0 },
        ...props.sx,
      }}
    />
  )
}
