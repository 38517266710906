import { Switch, switchClasses } from '@mui/base/Switch'
import { Stack, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { PropsWithChildren, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Icons } from '@/enums'
import { uiStore, useUiState } from '@/store'
import { UiIcon } from '@/ui'

function ThemeSwitcherThumb(props: { className: string }) {
  const { palette } = useTheme()
  const { t } = useTranslation()

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='center'
      spacing={2.5}
      className={props.className}
      bgcolor={palette.background.pure}
      sx={{
        boxSizing: 'border-box',
        width: '113px',
        height: '34px',
        top: '2px',
        left: '2px',
        borderRadius: '24px',
        position: 'relative',
        transitionProperty: 'all',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
        transitionDuration: '120ms',
      }}
    >
      <UiIcon
        name={palette.mode === 'dark' ? Icons.Moon : Icons.Sun}
        size={4}
        color={palette.text.primary}
      />
      <Typography variant='subtitle5' color={palette.text.primary}>
        {palette.mode === 'dark'
          ? t('theme-switcher.theme-option-dark')
          : t('theme-switcher.theme-option-light')}
      </Typography>
    </Stack>
  )
}

function ThemeSwitcherTrack(props: PropsWithChildren & { className: string }) {
  const { palette } = useTheme()
  const { t } = useTranslation()

  const renderBackgroundThumbPlaceholder = useCallback(
    (option: 'light' | 'dark') => {
      return (
        <Stack direction='row' alignItems='center' justifyContent='center' spacing={2.5}>
          <UiIcon
            name={option === 'dark' ? Icons.Moon : Icons.Sun}
            size={4}
            color={palette.text.primary}
          />
          <Typography variant='subtitle5' color={palette.text.primary}>
            {option === 'dark'
              ? t('theme-switcher.theme-option-dark')
              : t('theme-switcher.theme-option-light')}
          </Typography>
        </Stack>
      )
    },
    [palette, t],
  )

  return (
    <Stack
      direction='row'
      bgcolor={palette.action.active}
      width='100%'
      sx={{
        overflow: 'hidden',
        boxSizing: 'border-box',
        borderRadius: '24px',
        display: 'block',
        height: '100%',
        width: '100%',
        position: 'absolute',
        transitionProperty: 'all',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
        transitionDuration: '120ms',
      }}
    >
      <Stack
        direction='row'
        width='100%'
        sx={{
          justifyContent: 'space-around',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        {renderBackgroundThumbPlaceholder('light')}
        {renderBackgroundThumbPlaceholder('dark')}
      </Stack>
      {props.children}
    </Stack>
  )
}

export default function ThemeSwitcher() {
  const { isDarkMode } = useUiState()

  return (
    <Switch
      checked={isDarkMode}
      slots={{
        root: Root,
        thumb: ThemeSwitcherThumb,
        track: ThemeSwitcherTrack,
      }}
      slotProps={{
        thumb: {
          className: switchClasses.thumb,
        },
      }}
      onChange={uiStore.togglePaletteMode}
    />
  )
}

const Root = styled('span')(
  () => `
  box-sizing: border-box;
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 234px;
  height: 38px;
  cursor: pointer;

  &.${switchClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.${switchClasses.checked} {
    .${switchClasses.thumb} {
      left: 119px;
    }

    .${switchClasses.track} {
      border: none;
    }
  }

  & .${switchClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
  `,
)
