import { NotFoundError } from '@distributedlab/jac'
import { PROVIDERS } from '@distributedlab/w3p'
import { Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { getChallenge } from '@/api/modules/auth'
import { SignupModal } from '@/common'
import { Icons, RoutePaths } from '@/enums'
import { ErrorHandler, isMetamaskInstalled, isMobile, isSafari } from '@/helpers'
import BrowserNotSupportedModal from '@/pages/Home/components/BrowserNotSupportedModal'
import { authStore, useAuthState, useUiState, useWeb3State, web3Store } from '@/store'
import { levitateAnimation } from '@/theme/constants'
import { UiIcon } from '@/ui'

import BlockchainSection from './BlockchainSection'
import InstallMetaMaskModal from './InstallMetaMaskModal'

export default function HeroSection() {
  const [isBrowserNotSupportedModalShown, setIsBrowserNotSupportedModalShown] = useState(false)
  const [isInstallMetaMaskModalShown, setIsInstallMetaMaskModalShown] = useState(false)

  const [isSignupOpen, setIsSignupOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { isAuthorized, isLoggedIn, hasFinishedIntro, referralCode } = useAuthState()

  const { isConnected } = useWeb3State()
  const { t } = useTranslation()
  const { supportedPaletteMode } = useUiState()
  const navigate = useNavigate()

  const isMMInstalled = isMetamaskInstalled()
  const isSafariBrowser = isSafari()
  const isMobileDevice = isMobile()

  const handleSignIn = useCallback(async () => {
    if (!web3Store.provider.address) return
    const challenge = await getChallenge(web3Store.provider.address)
    const signature = await web3Store.provider.signMessage(challenge)
    await authStore.signIn(web3Store.provider.address, signature)
  }, [])

  const handleLogIn = useCallback(async () => {
    if (!web3Store.provider.address) return
    await authStore.logIn(web3Store.provider.address)
  }, [])

  const handleNavigationClick = useCallback(async () => {
    if (isMobileDevice && !isMMInstalled) {
      const link = referralCode
        ? `https://metamask.app.link/dapp/${window.location.origin}${generatePath(
            RoutePaths.InvitationAlias,
            {
              code: referralCode,
            },
          )}`
        : `https://metamask.app.link/dapp/${window.location.origin}`

      window.open(link)
      return
    }

    if (isSafariBrowser) {
      setIsBrowserNotSupportedModalShown(true)
      return
    }

    if (!isMMInstalled) {
      setIsInstallMetaMaskModalShown(true)
      return
    }

    setIsLoading(true)
    try {
      if (!isConnected) {
        await web3Store.init(PROVIDERS.Metamask)
      }
      if (!isAuthorized) {
        await handleSignIn()
      }
      if (!isLoggedIn) {
        await handleLogIn()
      }
      if (!hasFinishedIntro) {
        await authStore.updateHasFinishedIntro(web3Store.provider.address || '')
      }

      navigate(RoutePaths.Market)
    } catch (e) {
      if (e instanceof NotFoundError) {
        setIsSignupOpen(true)
        return
      }
      ErrorHandler.process(e)
    } finally {
      setIsLoading(false)
    }
  }, [
    isMobileDevice,
    isMMInstalled,
    isSafariBrowser,
    referralCode,
    isConnected,
    isAuthorized,
    isLoggedIn,
    hasFinishedIntro,
    navigate,
    handleSignIn,
    handleLogIn,
  ])

  return (
    <>
      <Stack
        component='section'
        data-aos='fade-down'
        sx={{
          gap: { xs: 5, md: 8 },
          pt: { xs: 40, md: 30, xl: 60 },
          pb: { xs: 36, md: 20, xl: 50 },
          alignItems: 'center',
          position: 'relative',
          height: { xs: '140svh', sm: '100svh' },
          minHeight: { xs: 850, md: 700 },
        }}
      >
        <HeroTitle />
        <Typography
          variant='body1'
          sx={theme => ({
            color: theme.palette.text.secondary,
            userSelect: 'none',
            typography: {
              xs: theme.typography.body2,
              md: theme.typography.body1,
            },
          })}
        >
          {t('home-page.hero-subtitle')}
        </Typography>
        <Button
          variant='outlined'
          color='primary'
          size='large'
          disabled={isLoading}
          sx={{ height: 56, px: 8, py: 4.5, mt: { xs: 3, md: 0 } }}
          onClick={handleNavigationClick}
        >
          {t('home-page.hero-btn')}
        </Button>
        <ScrollButton key={`scroll-button-${supportedPaletteMode}`} />
        <SignupModal
          open={isSignupOpen}
          onClose={() => setIsSignupOpen(false)}
          onSubmitSuccess={() => navigate(RoutePaths.Market)}
        />
        <BrowserNotSupportedModal
          open={isBrowserNotSupportedModalShown}
          onClose={() => setIsBrowserNotSupportedModalShown(false)}
        />
        <InstallMetaMaskModal
          open={isInstallMetaMaskModalShown}
          onClose={() => setIsInstallMetaMaskModalShown(false)}
        />
      </Stack>
      <BlockchainSection />
    </>
  )
}

const HeroTitle = () => {
  const { breakpoints } = useTheme()
  const isMdUp = useMediaQuery(breakpoints.up('md'))

  const intersectingCirclesSx = useMemo(
    () => ({
      position: 'relative',
      top: isMdUp ? 5 : 0,
      right: isMdUp ? -15 : 10,
    }),
    [isMdUp],
  )

  const iconSize = useMemo(() => (isMdUp ? 18 : 10), [isMdUp])

  return (
    <Typography
      component='h1'
      sx={theme => ({
        typography: {
          xs: theme.typography.display2,
          md: theme.typography.display1,
        },
        maxWidth: {
          xs: 317,
          md: 900,
        },
        position: {
          xs: 'relative',
          md: 'static',
        },
        userSelect: 'none',
        textAlign: 'center',
      })}
    >
      <Trans
        i18nKey='home-page.hero-title'
        components={{
          Clover: (
            <UiIcon
              name={Icons.Clover}
              size={iconSize}
              sx={{ position: 'relative', userSelect: 'none', right: isMdUp ? 11 : 6 }}
              color={({ palette }) => palette.secondary.main}
            />
          ),

          Leaf: (
            <UiIcon
              name={Icons.Leaf}
              size={iconSize}
              sx={{
                position: 'relative',
                left: isMdUp ? 15 : -18,
                ml: isMdUp ? 0 : 7,
              }}
              color={({ palette }) => palette.primary.main}
            />
          ),
          IntersectingCirclesMd: isMdUp ? (
            <UiIcon name={Icons.IntersectingCircles} size={iconSize} sx={intersectingCirclesSx} />
          ) : (
            <></>
          ),
          IntersectingCirclesXs: isMdUp ? (
            <></>
          ) : (
            <UiIcon name={Icons.IntersectingCircles} size={iconSize} sx={intersectingCirclesSx} />
          ),
        }}
      />
    </Typography>
  )
}

const ScrollButton = () => {
  const { t } = useTranslation()
  const { palette } = useTheme()

  const scrollToNextSection = () => {
    const section = document.querySelector('[data-features-section]')
    section?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Button
      data-aos-duration='1000'
      data-aos-delay='1000'
      data-aos='fade-zoom-in'
      data-aos-easing='ease-in-back'
      variant='text'
      sx={{
        width: 'fit-content',
        mx: 'auto',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 4,
      }}
      onClick={scrollToNextSection}
    >
      <Typography variant='body3' textAlign='center' color={palette.text.secondary}>
        {t('home-page.scroll-btn')}
      </Typography>
      <UiIcon
        color={palette.text.secondary}
        name={Icons.ArrowDownSLine}
        size={4}
        sx={{
          animation: `${levitateAnimation} 2s ease-in-out infinite`,
          animationDelay: 2000,
        }}
      />
    </Button>
  )
}
