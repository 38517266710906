import { JsonApiResponse } from '@distributedlab/jac'
import { BN } from '@distributedlab/tools'
import { t } from 'i18next'

import { MarketChartDataItem, Position, Question, TokenOutcome } from '@/types'

export const getTokenFromQuestion = (question: Question | null, outcome: boolean) => {
  // TODO: temp, questions should have a map of tokens
  return question?.tokens?.find(token => token.outcome === outcome) ?? null
}

export const getValuesFromPosition = (position: Position) => {
  const initialValue = BN.fromBigInt(position.conditional_tokens_amount || '0', 18).mul(
    BN.fromBigInt(position.average_price || position.token.top_bid.price || '0', 18),
  )

  const currentValue = BN.fromBigInt(position.conditional_tokens_amount || '0', 18).mul(
    BN.fromBigInt(position.token.top_bid.price || '0', 18),
  )

  const zero = BN.fromRaw(0)

  const percentValueChange = initialValue.eq(zero)
    ? zero
    : currentValue.sub(initialValue).div(initialValue).toGreaterDecimals(20).toDecimals(2)

  return {
    initialValue,
    currentValue,
    percentValueChange,
  }
}

// TODO: remove after chart data is fixed in the backend
export function prepareMarketChartData(
  response?: JsonApiResponse<MarketChartDataItem[]> | null,
): MarketChartDataItem[] {
  const sortedItems = response?.data.sort((a, b) => a.date - b.date) || []
  const nonZeroItems = sortedItems.filter(item => item.price !== '0')

  if (nonZeroItems.length === 1) {
    // Add a fake item to make the chart look better
    const fakeItem: MarketChartDataItem = { ...nonZeroItems[0], date: nonZeroItems[0].date - 60 }
    return [fakeItem, ...nonZeroItems]
  }

  return nonZeroItems
}

export function compareQuestionsYesPrice(a: Question, b: Question) {
  const aYesToken = getTokenFromQuestion(a, true)
  const bYesToken = getTokenFromQuestion(b, true)

  const aYesPrice = BN.fromBigInt(aYesToken?.top_bid.price || '0')
  const bYesPrice = BN.fromBigInt(bYesToken?.top_bid.price || '0')

  return aYesPrice.gt(bYesPrice) ? -1 : 1
}

export function getQuestionTokenLabel(question: Question | null, outcome: boolean) {
  return outcome === TokenOutcome.Yes
    ? (question?.metadata.yes_label ?? t('question-outcome-labels.yes'))
    : (question?.metadata.no_label ?? t('question-outcome-labels.no'))
}
