import { TypedDataField } from 'ethers'
import { useCallback } from 'react'

import { config } from '@/config'
import { useEip712 } from '@/hooks/eip712'
import { useWeb3State } from '@/store'
import { NegRiskCtfExchange__factory } from '@/types'
import { OrderStruct } from '@/types/contracts/NegRiskCtfExchange'

const exchangeTypes: Record<string, TypedDataField[]> = {
  Order: [
    { name: 'salt', type: 'uint256' },
    { name: 'maker', type: 'address' },
    { name: 'signer', type: 'address' },
    { name: 'taker', type: 'address' },
    { name: 'tokenId', type: 'uint256' },
    { name: 'makerAmount', type: 'uint256' },
    { name: 'takerAmount', type: 'uint256' },
    { name: 'expiration', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'feeRateBps', type: 'uint256' },
    { name: 'signatureType', type: 'uint8' },
    { name: 'side', type: 'uint8' },
  ],
}

export const useOrderEip712 = (address = config?.CTF_EXCHANGE_CONTRACT) => {
  const { provider } = useWeb3State()

  const { signTypedData, getNonce } = useEip712(address, NegRiskCtfExchange__factory, exchangeTypes)

  const orderSignTypedData = useCallback(
    async (order: OrderStruct) => {
      if (!provider.address) throw new Error('no address')

      return signTypedData(order)
    },
    [provider.address, signTypedData],
  )

  return {
    signTypedData: orderSignTypedData,
    getNonce,
  }
}
