import { BN } from '@distributedlab/tools'
import { useInterval } from '@reactuses/core'
import { constants } from 'ethers'
import { useCallback, useEffect, useMemo } from 'react'

import { config } from '@/config'
import { createContract, ErrorHandler } from '@/helpers'
import { useWeb3State, web3Store } from '@/store'
import { CollateralToken__factory } from '@/types/contracts'

export const useCollateralToken = (address = config.COLLATERAL_TOKEN_CONTRACT) => {
  const { provider, address: userAddr, contractConnector, balance } = useWeb3State()

  const contract = useMemo(() => {
    if (!contractConnector) return

    return createContract(address, contractConnector, CollateralToken__factory)
  }, [address, contractConnector])

  const loadBalance = useCallback(async () => {
    if (!provider.address || !contract) return

    try {
      const balance = await contract.contractInstance.balanceOf(provider.address)
      web3Store.setBalance(balance?.toString() ?? '')
    } catch (error) {
      ErrorHandler.processWithoutFeedback(error)
    }
  }, [contract, provider.address])

  useInterval(loadBalance, 5_000)

  useEffect(() => {
    if (!contract || !provider.address || Number(balance)) return

    loadBalance()
  }, [balance, contract, loadBalance, provider.address])

  const approve = useCallback(
    (spender: string) => {
      if (!address) throw new ReferenceError('Provider or contract not found')

      const data = contract?.contractInterface.encodeFunctionData('approve', [
        spender,
        constants.MaxUint256,
      ])

      const txBody = {
        to: address,
        data,
      }

      return provider.signAndSendTx?.(txBody)
    },
    [address, contract?.contractInterface, provider],
  )

  const getIsApproved = useCallback(
    async (spender: string) => {
      if (!contract || !userAddr) throw new ReferenceError('Provider or contract not found')

      const allowance = await contract?.contractInstance.allowance(userAddr, spender)
      return allowance.eq(constants.MaxUint256)
    },
    [contract, userAddr],
  )

  const mint = useCallback(
    async (amount: string) => {
      if (!userAddr) throw new TypeError('Invalid address or provider')

      const data = contract?.contractInterface.encodeFunctionData('mint', [
        userAddr,
        BN.fromRaw(amount).value,
      ])

      const txBody = {
        to: address,
        data,
      }

      return provider?.signAndSendTx?.(txBody)
    },
    [userAddr, contract?.contractInterface, address, provider],
  )

  return {
    approve,
    getIsApproved,
    mint,
  }
}
