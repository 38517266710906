import { lazy, Suspense } from 'react'
import {
  createBrowserRouter,
  LoaderFunctionArgs,
  Navigate,
  Outlet,
  redirect,
} from 'react-router-dom'

import { RoutePaths } from '@/enums'
import IntroLayout from '@/layouts/IntroLayout'
import Home from '@/pages/Home'

import { AppLoader } from './common'
import ErrorBoundaryFallback from './common/ErrorBoundaryFallback'
import HomeLayout from './layouts/HomeLayout'
import MainLayout from './layouts/MainLayout'
import { authStore } from './store'

export const createRouter = () => {
  const Leaderboard = lazy(() => import('@/pages/Leaderboard'))
  const Account = lazy(() => import('@/pages/Account'))
  const Market = lazy(() => import('@/pages/Market'))
  const Intro = lazy(() => import('@/pages/Intro'))
  const ActiveActivities = lazy(() => import('@/pages/Account/components/ActiveActivities'))
  const CompletedActivities = lazy(() => import('@/pages/Account/components/CompletedActivities'))
  const VerifyEmail = lazy(() => import('@/pages/Account/components/VerifyEmail'))
  const InvitationAlias = lazy(() => import('@/pages/InvitationAlias'))

  const welcomeGuard = () => {
    if (!authStore.accessToken || !authStore.username) {
      return redirect(RoutePaths.Home)
    }
    if (authStore.hasFinishedIntro) {
      return redirect(RoutePaths.Market)
    }

    return null
  }

  const authProtectedGuard = ({ request }: LoaderFunctionArgs) => {
    const requestUrl = new URL(request.url)
    const from = requestUrl.searchParams.get('from')
    // If the user is not logged in and tries to access protected route, we redirect
    // them to homepage with a `from` parameter that allows login to redirect back
    // to this page upon successful authentication
    if (!authStore.accessToken) {
      requestUrl.searchParams.set('from', requestUrl.pathname)

      return redirect(`${RoutePaths.Home}${requestUrl.search}`)
    }

    if (!authStore.hasFinishedIntro) {
      return redirect(RoutePaths.Intro)
    }

    return from ? redirect(`${from}`) : null
  }

  return createBrowserRouter([
    {
      path: RoutePaths.Home,
      element: <Outlet />,
      ErrorBoundary: () => <ErrorBoundaryFallback onReset={() => window.location.reload()} />,
      children: [
        {
          index: true,
          element: (
            <HomeLayout>
              <Home />
            </HomeLayout>
          ),
        },
        {
          path: RoutePaths.Intro,
          loader: welcomeGuard,
          element: (
            <IntroLayout>
              <Suspense fallback={<AppLoader />}>
                <Intro />
              </Suspense>
            </IntroLayout>
          ),
        },
        {
          path: RoutePaths.VerifyEmail,
          element: <VerifyEmail />,
        },
        {
          path: RoutePaths.InvitationAlias,
          element: <InvitationAlias />,
        },
        {
          element: (
            <MainLayout>
              <Suspense fallback={<></>}>
                <Outlet />
              </Suspense>
            </MainLayout>
          ),
          loader: authProtectedGuard,
          children: [
            {
              path: RoutePaths.Leaderboard,
              element: <Leaderboard />,
            },
            {
              path: RoutePaths.Market,
              element: <Market />,
            },
            {
              path: RoutePaths.Account,
              element: <Account />,
              children: [
                {
                  index: true,
                  element: <Navigate to={RoutePaths.AccountActive} replace />,
                },
                {
                  path: RoutePaths.AccountActive,
                  element: <ActiveActivities />,
                },
                {
                  path: RoutePaths.AccountHistory,
                  element: <CompletedActivities />,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <Navigate replace to={RoutePaths.Home} />,
    },
  ])
}
