import { NetworkName } from '@/constants'

import packageJson from '../package.json'

export type Config = {
  MODE: 'development' | 'production'
  APP_NAME: string
  APP_HOST_URL: string
  BUILD_VERSION: string

  API_URL: string
  NETWORK: NetworkName

  COLLATERAL_TOKEN_CONTRACT: string
  CONDITIONAL_TOKEN_CONTRACT: string
  ADAPTER_CONTRACT: string
  CTF_EXCHANGE_CONTRACT: string
  RARIMARKET_ACCOUNT_FACTORY: string

  SENTRY_DSN: string

  GTM_ID: string
}

export const config: Config = {
  MODE: import.meta.env.VITE_MODE,
  APP_NAME: import.meta.env.VITE_APP_NAME,
  APP_HOST_URL: import.meta.env.VITE_APP_HOST_URL,
  BUILD_VERSION: packageJson.version || import.meta.env.VITE_APP_BUILD_VERSION,

  API_URL: import.meta.env.VITE_API_URL,
  NETWORK: import.meta.env.VITE_NETWORK || 'testnet',

  COLLATERAL_TOKEN_CONTRACT: import.meta.env.VITE_COLLATERAL_TOKEN_CONTRACT,
  CONDITIONAL_TOKEN_CONTRACT: import.meta.env.VITE_CONDITIONAL_TOKEN_CONTRACT,
  ADAPTER_CONTRACT: import.meta.env.VITE_ADAPTER_CONTRACT,
  CTF_EXCHANGE_CONTRACT: import.meta.env.VITE_CTF_EXCHANGE_CONTRACT,
  RARIMARKET_ACCOUNT_FACTORY: import.meta.env.VITE_RARIMARKET_ACCOUNT_FACTORY,

  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,

  GTM_ID: import.meta.env.VITE_GTM_ID,
}
