import { JsonApiClientRequestOpts } from '@distributedlab/jac'

import { api } from '@/api/clients'
import { ApiServicePaths } from '@/enums'
import type { Market, MarketChartDataItem } from '@/types'

export const getMarket = async (opts?: Partial<JsonApiClientRequestOpts>) => {
  return api.get<Market>(`${ApiServicePaths.OrderBook}/v1/public/markets`, opts)
}

export const getMarketById = async (marketId: string, opts?: Partial<JsonApiClientRequestOpts>) => {
  return api.get<Market>(`${ApiServicePaths.OrderBook}/v1/public/markets/${marketId}`, opts)
}

export const getChartData = async (tokenId: string, opts?: Partial<JsonApiClientRequestOpts>) => {
  return api.get<MarketChartDataItem[]>(
    `${ApiServicePaths.OrderBook}/v1/public/tokens/${tokenId}/chart`,
    opts,
  )
}
