import { type BigNumberish, TypedDataField } from 'ethers'
import { useCallback } from 'react'

import { config } from '@/config'
import { ErrorHandler } from '@/helpers'
import { CollateralToken__factory, ConditionalToken__factory } from '@/types'

import { useEip712 } from './eip712'

// conditional
const PermitForAllTypes: Record<string, TypedDataField[]> = {
  PermitForAll: [
    { name: 'owner', type: 'address' },
    { name: 'spender', type: 'address' },
    { name: 'nonce', type: 'uint256' },
    { name: 'deadline', type: 'uint256' },
  ],
}

// collateral
const PermitTypes: Record<string, TypedDataField[]> = {
  Permit: [
    { name: 'owner', type: 'address' },
    { name: 'spender', type: 'address' },
    { name: 'value', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'deadline', type: 'uint256' },
  ],
}

export const useCollateralEip712 = (address = config?.COLLATERAL_TOKEN_CONTRACT) => {
  const { contractInstance, signTypedData, getNonce } = useEip712(
    address,
    CollateralToken__factory,
    PermitTypes,
  )

  const getAllowance = useCallback(
    async (owner: string, spender: string) => {
      if (!contractInstance) throw new Error('no contract instance')

      const allowance = await contractInstance.allowance(owner, spender)

      return allowance
    },
    [contractInstance],
  )

  const signCollateralTypedData = useCallback(
    async (
      owner: string,
      spender: string,
      value: BigNumberish,
      nonce: number,
      deadline: BigNumberish,
    ) => {
      try {
        return signTypedData({
          owner,
          spender,
          nonce,
          value,
          deadline,
        })
      } catch (e) {
        ErrorHandler.process(e)
      }
    },
    [signTypedData],
  )

  return {
    signTypedData: signCollateralTypedData,
    getAllowance,
    getNonce,
  }
}

export const useConditionalEip712 = (address = config?.CONDITIONAL_TOKEN_CONTRACT) => {
  const { contractInstance, signTypedData, getNonce } = useEip712(
    address,
    ConditionalToken__factory,
    PermitForAllTypes,
  )

  const getAllowance = useCallback(
    async (owner: string, spender: string) => {
      if (!contractInstance) throw new Error('no contract instance')

      const allowance = await contractInstance.isApprovedForAll(owner, spender)

      return allowance
    },
    [contractInstance],
  )

  const signConditionalTypedData = useCallback(
    async (owner: string, spender: string, nonce: number, deadline: BigNumberish) => {
      try {
        return signTypedData({
          owner,
          spender,
          nonce,
          deadline,
        })
      } catch (e) {
        ErrorHandler.process(e)
      }
    },
    [signTypedData],
  )

  return {
    signTypedData: signConditionalTypedData,
    getAllowance,
    getNonce,
  }
}
