import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import zIndex from '@mui/material/styles/zIndex'
import { NavLink } from 'react-router-dom'

import SettingsMenu from '@/common/SettingsMenu'
import { Icons, RoutePaths } from '@/enums'
import { useRouteTitle } from '@/hooks'
import { UiIcon } from '@/ui'

export default function AppHeader() {
  const routeName = useRouteTitle()
  const { palette, breakpoints, spacing } = useTheme()

  const isMdDown = useMediaQuery(() => breakpoints.down('md'))

  return (
    <Stack
      direction='row'
      position='fixed'
      zIndex={zIndex.appBar - 1}
      justifyContent='center'
      alignItems='center'
      top={0}
      py={{ xs: 3, md: 5 }}
      left={{ xs: 0, md: spacing(22) }}
      right={0}
      px={6}
      // HACK min-height is the sum of 2*py and 2*py + icon size of absolutely positioned element
      minHeight={{ xs: 60, md: 80 }}
    >
      {isMdDown && (
        <Stack
          component={NavLink}
          to={RoutePaths.Home}
          alignItems='center'
          sx={{
            [breakpoints.down('md')]: {
              position: 'absolute',
              left: spacing(6),
            },
          }}
        >
          <UiIcon name={Icons.App} size={10} color={palette.text.primary} />
        </Stack>
      )}
      <Typography variant='h5' color={palette.text.primary}>
        {routeName}
      </Typography>

      {isMdDown && (
        <SettingsMenu
          sx={{
            [breakpoints.down('md')]: {
              position: 'absolute',
              right: spacing(6),
            },
          }}
        />
      )}
    </Stack>
  )
}
