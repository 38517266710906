import { Box, Stack, StackProps, useTheme } from '@mui/material'
import { ReactNode } from 'react'

interface Props extends StackProps {
  src?: string
  alt?: string
  size: number
  fallback?: ReactNode
}

export default function LogoAvatar({ src, alt, size, fallback, ...rest }: Props) {
  const { spacing, palette } = useTheme()

  if (!src && !fallback) return null

  return (
    <Stack
      bgcolor={palette.primary.lighter}
      justifyContent='center'
      alignItems='center'
      borderRadius='50%'
      minWidth={spacing(size)}
      width={spacing(size)}
      height={spacing(size)}
      overflow='hidden'
      {...rest}
    >
      {src ? (
        <Box
          component='img'
          src={src}
          alt={alt}
          sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      ) : (
        fallback
      )}
    </Stack>
  )
}
