import { OrderSide } from '@/types'

export type Order = {
  tokenID: string
  side: OrderSide
  price: string
  amount: string
}

export enum PermitTokenTypes {
  CollateralExchange = 'collateral_exchange',
  CollateralAdapter = 'collateral_adapter',
  ConditionalExchange = 'conditional_exchange',
  ConditionalAdapter = 'conditional_adapter',
}
