import {
  Backdrop,
  Collapse,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Zoom,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import AppLogo from '@/common/AppLogo'
import { Icons, RoutePaths } from '@/enums'
import { uiStore, useUiState } from '@/store'
import { UiIcon } from '@/ui'

export default function HomeHeader() {
  const { breakpoints } = useTheme()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const isMdUp = useMediaQuery(breakpoints.up('md'))

  const toggleMenu = () => {
    setIsOpen(prevState => !prevState)
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    if (isMdUp) {
      setIsOpen(false)
    }
  }, [isMdUp])

  return (
    <>
      <Stack
        component='header'
        sx={theme => ({
          position: 'fixed',
          py: { xs: 0, md: 5, xl: 10 },
          px: { xs: 5, lg: 0 },
          top: 0,
          left: 0,
          right: 0,
          zIndex: theme.zIndex.appBar,
          background: theme.palette.background.pure,
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          width: 1,
        })}
      >
        <Stack
          sx={{
            flexDirection: { xs: 'row' },
            maxWidth: { md: 1032 },
            py: { xs: 4, lg: 0 },
            justifyContent: 'space-between',
            alignItems: 'center',
            width: 1,
            mx: 'auto',
          }}
        >
          <AppLogo color={({ palette }) => palette.primary.main} onClick={scrollTop} />
          {isMdUp ? (
            <Navbar />
          ) : (
            <Stack direction='row' spacing={6}>
              <ThemeButton />
              {/* TODO: uncomment when the pages are ready */}
              {/* <BurgerMenuButton onClick={toggleMenu} isOpen={isOpen} /> */}
            </Stack>
          )}
        </Stack>
        <Collapse in={isOpen} unmountOnExit>
          <Stack>
            <Navbar />
          </Stack>
        </Collapse>
      </Stack>
      <Backdrop
        sx={theme => ({ color: theme.palette.background.default, zIndex: theme.zIndex.appBar - 1 })}
        open={isOpen}
        onClick={toggleMenu}
      />
    </>
  )
}

const Navbar = () => {
  const { t } = useTranslation()
  const { breakpoints } = useTheme()
  const isMdUp = useMediaQuery(breakpoints.up('md'))

  const links = useMemo(
    () => [
      // TODO: Uncomment when the pages are ready
      // {
      //   route: RoutePaths.Market,
      //   text: t('home-page.documentation-link'),
      // },
      // {
      //   route: RoutePaths.Account,
      //   text: t('home-page.faq-link'),
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )

  return (
    <Stack
      sx={{
        minWidth: { xs: 300 },
        width: { xs: 1, md: 'auto' },
        flexDirection: { xs: 'column', md: 'row' },
        gap: { xs: 8, md: 10 },
        py: { xs: 14, md: 0 },
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      {links.map(({ route, text }, index) => (
        <NavbarLink to={route} text={text} key={index} />
      ))}
      {isMdUp && <ThemeButton />}
    </Stack>
  )
}

type NavbarLinkProps = {
  to: RoutePaths
  text: string
}

const NavbarLink = ({ to, text }: NavbarLinkProps) => {
  const { palette } = useTheme()

  return (
    <NavLink to={to}>
      <Typography
        variant='subtitle4'
        sx={theme => ({
          alignSelf: 'baseline',
          userSelect: 'none',
          color: palette.text.primary,
          typography: { xs: theme.typography.subtitle2, md: theme.typography.subtitle4 },
        })}
      >
        {text}
      </Typography>
    </NavLink>
  )
}

const ThemeButton = () => {
  const { isDarkMode, supportedPaletteMode } = useUiState()

  return (
    <IconButton key={supportedPaletteMode} onClick={uiStore.togglePaletteMode}>
      <Zoom in={isDarkMode} unmountOnExit>
        <UiIcon name={Icons.Moon} size={5} />
      </Zoom>
      <Zoom in={!isDarkMode} unmountOnExit>
        <UiIcon name={Icons.Sun} size={5} />
      </Zoom>
    </IconButton>
  )
}

type BurgerMenuButtonProps = {
  isOpen: boolean
  onClick: () => void
}

// TODO: Uncomment when the pages are ready
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BurgerMenuButton = ({ onClick, isOpen }: BurgerMenuButtonProps) => {
  return (
    <IconButton
      key={`menu-open-${isOpen}`}
      sx={({ palette }) => ({
        width: 40,
        height: 40,
        background: palette.background.default,
      })}
      onClick={onClick}
    >
      <Zoom in={isOpen} unmountOnExit>
        <UiIcon name={Icons.Close} size={5} />
      </Zoom>
      <Zoom in={!isOpen} unmountOnExit>
        <UiIcon name={Icons.Menu} size={5} />
      </Zoom>
    </IconButton>
  )
}
