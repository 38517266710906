import { Box, Stack, Typography, useTheme } from '@mui/material'
import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import CommentsAnimation from './CommentsAnimation'
import DailyMarketAnimation from './DailyMarketAnimation'

export default function FeaturesSection() {
  const { t } = useTranslation()
  const { breakpoints } = useTheme()

  const cards = useMemo(
    () => [
      {
        title: t('home-page.card-1-title'),
        description: t('home-page.card-1-description'),
        illustration: <DailyMarketAnimation />,
      },
      {
        title: t('home-page.card-2-title'),
        description: t('home-page.card-2-description'),
        illustration: <CommentsAnimation />,
      },
    ],
    [t],
  )

  return (
    <Box
      component='section'
      data-aos='fade-up'
      data-aos-anchor-placement='center-bottom'
      data-features-section
      sx={{
        pt: { xs: 20, xl: 30 },
        pb: { xs: 10, xl: 50 },
        display: 'grid',
        justifyContent: 'center',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        alignItems: 'flex-start',
        gap: { xs: 10, md: 8 },
        '&.aos-init': { scrollMarginTop: 100 },
        '&.aos-animate': { scrollMarginTop: 0 },
        [breakpoints.down('sm')]: {
          gridTemplateColumns: '1fr',
        },
      }}
    >
      {cards.map((item, index) => (
        <FeatureCard {...item} key={index} />
      ))}
    </Box>
  )
}

type FeatureCardProps = {
  title: string
  description: string
  illustration: ReactNode
}

const FeatureCard = ({ title, description, illustration }: FeatureCardProps) => {
  return (
    <Stack spacing={{ xs: 6, md: 8 }}>
      <Stack
        sx={({ palette }) => ({
          background: palette.action.active,
          aspectRatio: '3/2',
          width: 1,
          borderRadius: 4,
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        {illustration}
      </Stack>
      <Stack spacing={4}>
        <Typography variant='h3' sx={({ typography }) => ({ typography: { xs: typography.h4 } })}>
          {title}
        </Typography>
        <Typography
          variant='body2'
          sx={theme => ({
            color: theme.palette.text.secondary,
            typography: { xs: theme.typography.body3, md: theme.typography.body2 },
          })}
        >
          {description}
        </Typography>
      </Stack>
    </Stack>
  )
}
