import { Stack, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  Bodies,
  Body,
  Composite,
  Engine,
  Mouse,
  MouseConstraint,
  Render,
  Runner,
  World,
} from 'matter-js'
import { forwardRef, useCallback, useEffect, useRef } from 'react'

import { Icons } from '@/enums'
import { useViewportSizes } from '@/hooks'
import { Transitions } from '@/theme/constants'
import { UiIcon } from '@/ui'

type BoxItem = {
  text?: string
  icon?: IconProps
}

type IconProps = { name: Icons; size?: number; sx?: SxProps }

const boxes: BoxItem[] = [
  { text: 'CRYPTO' },
  { text: 'ETHEREUM' },
  { text: 'TOKENS' },
  { text: 'BITCOIN' },
  { text: 'DEGEN' },
  { text: 'MEME' },
  { text: 'LOCK' },
  { text: 'STAKE' },
  { text: 'RARIMO' },
  { text: 'DOGE' },
  { text: 'WEN' },
  { text: 'SOLANA' },
  { text: 'PROTOCOL' },
  { text: 'USDC' },
  { text: 'SHIBA' },
  { text: 'DOT' },
  { icon: { name: Icons.Check } },
  { icon: { name: Icons.Close } },
  {
    icon: { name: Icons.Circle, size: 7, sx: { position: 'relative', left: 10, p: 0 } },
  },
  {
    icon: { name: Icons.Circle, size: 7, sx: { position: 'relative', left: 10, p: 0 } },
  },
  { text: 'RARIMO' },
  { text: 'TOKENS' },
  { text: 'ETHEREUM' },
  { text: 'CRYPTO' },
  { text: 'TOKENS' },
  { text: 'BITCOIN' },
  { text: 'DEGEN' },
  { text: 'MEME' },
]

export default function BlockchainSection() {
  const { breakpoints } = useTheme()
  const isMdDown = useMediaQuery(() => breakpoints.down('md'))

  const containerRef = useRef<HTMLDivElement | null>(null)
  const renderRef = useRef<Render | null>(null)
  const engineRef = useRef<Engine | null>(null)
  const worldRef = useRef<World | null>(null)
  const animationFrameRef = useRef<number>(-1)
  const boxRefs = useRef<(HTMLDivElement | null)[]>([])

  const { viewportWidth } = useViewportSizes()

  const createBoxes = () => {
    const midPoint = boxes.length / 2
    return boxes.slice(0, isMdDown ? 20 : boxes.length).map((_, id) => {
      const elem = boxRefs.current[id]
      if (!elem) return

      const xDelta = Math.random() * 50 + 50
      const startX = id > midPoint ? window.innerWidth - xDelta : xDelta

      return {
        w: elem.clientWidth,
        h: elem.clientHeight,
        body: Bodies.rectangle(
          startX,
          Math.random() * 5 + 10,
          elem.offsetWidth,
          elem.offsetHeight,
          {
            timeScale: 0.65,
            angle: Math.random() * 10 + 15,
            friction: 0.3,
            restitution: 0.005,
            density: 0.01,
            chamfer: { radius: 22 },
            render: { visible: false },
          },
        ),
        elem,
        render() {
          const { x, y } = this.body.position
          if (!this.elem) return

          this.elem.style.top = `${y - this.h / 2}px`
          this.elem.style.left = `${x - this.w / 2}px`
          this.elem.style.transform = `rotate(${this.body.angle}rad)`
        },
      }
    })
  }

  const createWalls = () => {
    if (!containerRef.current) return
    const containerRect = containerRef.current.getBoundingClientRect()

    const top = Bodies.rectangle(containerRect.width / 2, 0, containerRect.width, 10, {
      isStatic: true,
    })

    const bottom = Bodies.rectangle(
      containerRect.width / 2,
      containerRect.height,
      containerRect.width,
      1,
      {
        isStatic: true,
      },
    )

    const right = Bodies.rectangle(
      containerRect.width,
      containerRect.height / 2,
      10,
      containerRect.height,
      {
        isStatic: true,
      },
    )

    const left = Bodies.rectangle(0, containerRect.height / 2, 1, containerRect.height, {
      isStatic: true,
    })

    top.render.visible = false
    left.render.visible = false
    right.render.visible = false
    bottom.render.visible = false

    return [top, left, right, bottom]
  }

  const start = useCallback(() => {
    if (!containerRef.current) return
    const containerRect = containerRef.current.getBoundingClientRect()

    engineRef.current = Engine.create({
      positionIterations: 10,
      velocityIterations: 10,
    })
    worldRef.current = engineRef.current.world

    renderRef.current = Render.create({
      element: containerRef.current,
      engine: engineRef.current,
      options: {
        width: containerRect.width,
        showInternalEdges: true,
        height: containerRect.height,
        wireframes: false,
        background: 'transparent',
      },
    })

    Render.run(renderRef.current)

    const boxObjects = createBoxes()

    Composite.add(
      worldRef.current,
      boxObjects.map(box => box!.body),
    )

    const runner = Runner.create()
    Runner.run(runner, engineRef.current)

    Composite.add(worldRef.current, createWalls() as Body[])

    const mouse = Mouse.create(document.body)
    const mouseConstraint = MouseConstraint.create(engineRef.current, {
      mouse,
      constraint: {
        render: {
          visible: false,
        },
      },
    })

    // @ts-expect-error the property is private
    mouseConstraint.mouse.element.removeEventListener('wheel', mouseConstraint.mouse.mousewheel)
    // @ts-expect-error the property is private
    mouseConstraint.mouse.element.removeEventListener('touchstart', mouseConstraint.mouse.mousedown)
    // @ts-expect-error the property is private
    mouseConstraint.mouse.element.removeEventListener('touchmove', mouseConstraint.mouse.mousemove)
    // @ts-expect-error the property is private
    mouseConstraint.mouse.element.removeEventListener('touchend', mouseConstraint.mouse.mouseup)

    Composite.add(worldRef.current, mouseConstraint)

    const animate = () => {
      boxObjects.forEach(box => box!.render())
      Engine.update(engineRef.current as Engine)
      animationFrameRef.current = requestAnimationFrame(animate)
    }

    animate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    start()

    return () => {
      World.clear(worldRef.current as World, false)
      Render.stop(renderRef.current as Render)
      Engine.clear(engineRef.current as Engine)
      renderRef.current?.canvas.remove()
      cancelAnimationFrame(animationFrameRef.current)
    }
  }, [start, viewportWidth])

  return (
    <Stack
      ref={containerRef}
      component='section'
      sx={({ palette }) => ({
        width: 1,
        height: { xs: '140svh', sm: '100svh' },
        minHeight: { xs: 850, md: 700 },
        position: 'absolute',
        inset: 0,
        zIndex: 1,
        px: 1,
        overflow: 'hidden',
        transition: Transitions.Gentle,
        borderBottom: `1px solid ${palette.text.primary}`,
        pointerEvents: 'none',
      })}
    >
      {boxes.map((box, id) => (
        <BoxItem {...box} key={id} ref={el => (boxRefs.current[id] = el)} />
      ))}
    </Stack>
  )
}

const BoxItem = forwardRef<HTMLDivElement, BoxItem>(({ text, icon }, ref) => {
  return (
    <Stack
      ref={ref}
      sx={({ palette }) => ({
        left: 0,
        position: 'absolute',
        border: `1px solid ${palette.text.primary}`,
        borderRadius: 25,
        px: 5,
        py: 2,
        userSelect: 'none',
        backgroundColor: palette.background.paper,
        color: palette.text.primary,
        zIndex: 1,
      })}
    >
      {text ? <Typography variant='h4'>{text}</Typography> : icon && <UiIcon {...icon} />}
    </Stack>
  )
})
