import CommunitySection from './components/CommunitySection'
import FeaturesSection from './components/FeaturesSection'
import HeroSection from './components/HeroSection'
import HomeFooter from './components/HomeFooter'
import HomeHeader from './components/HomeHeader'
import PromoSection from './components/PromoSection'

export default function Home() {
  return (
    <>
      <HomeHeader />
      <HeroSection />
      <FeaturesSection />
      <PromoSection />
      <CommunitySection />
      <HomeFooter />
    </>
  )
}
