export enum RoutePaths {
  Home = '/',
  Account = '/account',
  AccountActive = '/account/active',
  AccountHistory = '/account/history',
  VerifyEmail = '/verify-email',
  Market = '/market',
  Intro = '/welcome',
  Leaderboard = '/leaderboard',
  InvitationAlias = '/i/:code',
}
