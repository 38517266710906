import { Link, Stack, Typography, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Transitions } from '@/theme/constants'

export default function HomeFooter() {
  const { t } = useTranslation()
  const { palette } = useTheme()

  return (
    <Stack
      component='footer'
      data-aos-duration='500'
      data-aos='fade-zoom-in'
      data-aos-easing='ease-in-back'
    >
      <Stack height='1px' width='100%' sx={{ background: palette.additional.gradient }} />
      <Stack
        justifyContent='space-between'
        alignItems='center'
        direction={{ xs: 'column', md: 'row' }}
        py={{ xs: 8, md: 10 }}
        spacing={{ xs: 2, md: 4 }}
      >
        <Typography variant='body3' color={palette.text.secondary}>
          <Trans i18nKey='home-page.copyright-txt' values={{ year: new Date().getFullYear() }} />
        </Typography>
        <Stack
          spacing={{ xs: 2, sm: 4 }}
          direction={{ xs: 'column', sm: 'row' }}
          alignItems='center'
        >
          <FooterLink href='/terms'>{t('home-page.terms')}</FooterLink>
          <FooterLink href='/privacy'>{t('home-page.privacy')}</FooterLink>
        </Stack>
        <Typography variant='body3' color={palette.text.secondary}>
          <Trans
            i18nKey='home-page.built-on-txt'
            components={{ Link: <FooterLink href='https://rarimo.com/' /> }}
          />
        </Typography>
      </Stack>
    </Stack>
  )
}

const FooterLink = ({ href, children }: { href: string } & PropsWithChildren) => {
  return (
    <Link
      href={href}
      variant='body3'
      target='_blank'
      rel='noreferrer'
      sx={({ palette }) => ({
        transition: Transitions.Default,
        color: palette.text.secondary,
        textDecorationColor: palette.text.secondary,
        '&:hover, &:active': {
          color: palette.text.primary,
        },
      })}
    >
      {children}
    </Link>
  )
}
