import { Box, BoxProps, Stack, StackProps, useTheme } from '@mui/material'

type Props = {
  parentBackgroundColor: string
  subtractPosition?: 'top' | 'bottom'
} & StackProps

export default function UiSubtractCard({
  parentBackgroundColor,
  subtractPosition = 'bottom',
  sx,
  children,
  ...rest
}: Props) {
  const { palette } = useTheme()

  const subtractSx = {
    position: 'absolute',
    borderRadius: '50%',
    width: 20,
    height: 20,
    background: parentBackgroundColor,
  }

  return (
    <Stack
      {...rest}
      sx={{
        background: palette.background.paper,
        p: 4,
        borderRadius: 3,
        position: 'relative',
        ...sx,
      }}
    >
      {subtractPosition === 'bottom' ? (
        <>
          <WrappedSubtraction
            sx={{
              ...subtractSx,
              bottom: 0,
              left: 0,
              transform: 'translate(-50%, 50%)',
            }}
          />
          <WrappedSubtraction
            sx={{
              ...subtractSx,
              bottom: 0,
              right: 0,
              transform: 'translate(50%, 50%)',
            }}
          />
        </>
      ) : (
        <>
          <WrappedSubtraction
            sx={{
              ...subtractSx,
              top: 0,
              left: 0,
              transform: 'translate(-50%, -50%)',
            }}
          />
          <WrappedSubtraction
            sx={{
              ...subtractSx,
              top: 0,
              right: 0,
              transform: 'translate(50%, -50%)',
            }}
          />
        </>
      )}
      {children}
    </Stack>
  )
}

function WrappedSubtraction(props: BoxProps) {
  const { palette } = useTheme()

  return (
    <Box {...props} sx={{ ...props.sx, background: palette.background.light }}>
      <Box
        sx={{
          ...props.sx,
          position: 'relative',
          top: 'none',
          left: 'none',
          bottom: 'none',
          right: 'none',
          transform: 'none',
          width: '100%',
          height: '100%',
          borderRadius: '50%',
        }}
      />
    </Box>
  )
}
