import { JsonApiError } from '@distributedlab/jac'
import {
  Box,
  Button,
  Dialog,
  DialogProps,
  FormControl,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  checkAccountExistenceNoConflicts,
  createAccount,
  sendVerificationEmail,
  uploadAvatar,
} from '@/api/modules/account'
import { Icons } from '@/enums'
import { ErrorHandler } from '@/helpers'
import { useForm } from '@/hooks'
import { authStore, useAuthState, useWeb3State } from '@/store'
import { Transitions } from '@/theme/constants'
import { UiDialogContent, UiIcon, UiTextField } from '@/ui'

import AvatarInput from './AvatarInput'

enum FieldNames {
  ProfileImage = 'profile-image',
  Username = 'username',
  Email = 'email',
}

const safeCheckUsername = async (username: string): Promise<boolean> => {
  try {
    await checkAccountExistenceNoConflicts(username)

    return true
  } catch (error) {
    if (!(error instanceof JsonApiError) || error.httpStatus !== 409) {
      return true
    }

    ErrorHandler.processWithoutFeedback(error)
  }

  return false
}

const safeCheckEmail = async (username: string, email: string): Promise<boolean> => {
  try {
    await checkAccountExistenceNoConflicts(username, email)

    return true
  } catch (error) {
    if (!(error instanceof JsonApiError) || error.httpStatus !== 409) {
      return true
    }

    if (error.httpStatus === 409) {
      const isEmailUsed = error.originalError.response?.data?.errors?.some(
        el => el.code === 'email_exists',
      )

      return !isEmailUsed
    }

    ErrorHandler.processWithoutFeedback(error)
  }

  return false
}

export default function SignupModal({
  open,
  onSubmitSuccess,
  ...rest
}: { onSubmitSuccess?: () => void } & DialogProps) {
  const [avatar, setAvatar] = useState<File | null>(null)
  const { spacing } = useTheme()
  const { t } = useTranslation()
  const { address } = useWeb3State()
  const { referralCode } = useAuthState()

  const formValues: {
    [FieldNames.ProfileImage]: string
    [FieldNames.Username]: string
    [FieldNames.Email]: string
  } = {
    [FieldNames.ProfileImage]: '',
    [FieldNames.Username]: '',
    [FieldNames.Email]: '',
  }

  const { handleSubmit, control, isFormDisabled, getErrorMessage, disableForm, enableForm, reset } =
    useForm(
      formValues,
      yup =>
        yup.object().shape({
          [FieldNames.Username]: yup
            .string()
            .min(3)
            .max(20)
            .matches(/^(?!_).*(?<!_)$/, t('signup-modal.incorrect-underscore-position'))
            .matches(/^[a-zA-Z]/, t('signup-modal.incorrect-first-letter'))
            .matches(/^[a-zA-Z0-9]+([_]?[a-zA-Z0-9]+)*$/, t('signup-modal.incorrect-symbols'))
            .required()
            .test('username-exists', t('signup-modal.username-exists-error'), async value => {
              if (!value) return true

              const isUsernameAllowed = await safeCheckUsername(value)

              return Boolean(isUsernameAllowed)
            }),
          [FieldNames.Email]: yup
            .string()
            .max(64)
            .email()
            .test('email-exists', t('signup-modal.email-exists-error'), async function (this) {
              if (!this.parent[FieldNames.Email]) return true

              const isEmailAllowed = await safeCheckEmail(
                this.parent[FieldNames.Username],
                this.parent[FieldNames.Email],
              )

              return Boolean(isEmailAllowed)
            }),
        }),
      {
        mode: 'onBlur',
      },
    )

  const submit = useCallback(
    async (formData: typeof formValues) => {
      disableForm()
      try {
        const { data: acc } = await createAccount({
          address: address || '',
          username: formData[FieldNames.Username],
          email: formData[FieldNames.Email],
          referral: referralCode,
          profileImg: await uploadAvatar(avatar, address || ''),
        })
        if (formData[FieldNames.Email]) {
          await sendVerificationEmail(address || '')
        }
        authStore.setAccount(acc)
        authStore.setReferralCode('')
        onSubmitSuccess?.()
      } catch (e) {
        if (!(e instanceof JsonApiError) || !e.httpStatus) {
          ErrorHandler.process(e)
        }
      } finally {
        enableForm()
      }
    },
    [address, avatar, disableForm, enableForm, onSubmitSuccess, referralCode],
  )

  useEffect(() => {
    if (open) {
      reset()
      setAvatar(null)
    }
  }, [open, reset])

  return (
    <Dialog
      {...rest}
      open={open}
      PaperProps={{
        ...rest.PaperProps,
        component: 'form',
        noValidate: true,
        onSubmit: handleSubmit(submit),
        sx: { width: spacing(138) },
        position: 'relative',
      }}
    >
      <IconButton
        onClick={e => rest?.onClose?.(e, 'backdropClick')}
        aria-label='close'
        sx={{
          position: 'absolute',
          top: spacing(6),
          right: spacing(6),
        }}
      >
        <UiIcon name={Icons.Close} size={5} />
      </IconButton>
      <UiDialogContent component={Stack} gap={8} alignItems='center' p={{ xs: 6, md: 10 }}>
        <Stack spacing={2} alignItems='center'>
          <Typography variant='h4'>{t('signup-modal.title')}</Typography>
          <Typography variant='body3' color={({ palette }) => palette.text.secondary}>
            {t('signup-modal.description')}
          </Typography>
        </Stack>
        <Controller
          name={FieldNames.ProfileImage}
          control={control}
          render={({ field }) => (
            <FormControl sx={{ alignItems: 'center' }}>
              <AvatarInput
                {...field}
                onUpdate={setAvatar}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: theme => theme.spacing(4),
                  color: ({ palette }) => palette.text.secondary,
                  transition: Transitions.Default,
                  '&:hover': {
                    color: ({ palette }) => palette.text.primary,
                  },
                }}
              >
                {avatar ? (
                  <Box
                    sx={{
                      width: 64,
                      height: 64,
                      position: 'relative',
                    }}
                  >
                    <Box
                      component='img'
                      src={URL.createObjectURL(avatar)}
                      sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '50%',
                        objectFit: 'cover',
                      }}
                    />
                    <IconButton
                      onClick={e => {
                        e.preventDefault()
                        setAvatar(null)
                      }}
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        p: 1,
                        backgroundColor: ({ palette }) => palette.background.paper,
                        border: '1px solid',
                        borderColor: ({ palette }) => palette.action.active,
                        boxShadow: '0px 2px 4px 0px #0000001F',
                        '&:hover': {
                          backgroundColor: ({ palette }) => palette.background.default,
                        },
                      }}
                    >
                      <UiIcon
                        name={Icons.DeleteBin7Line}
                        size={3}
                        color={({ palette }) => palette.error.main}
                      />
                    </IconButton>
                  </Box>
                ) : (
                  <UiIcon name={Icons.DefaultAccountImage} size={16} />
                )}
                <Typography variant='h6'>
                  {avatar
                    ? t('signup-modal.upload-filled-description')
                    : t('signup-modal.upload-description')}
                </Typography>
              </AvatarInput>
            </FormControl>
          )}
        />
        <Stack spacing={4} width='100%'>
          <Controller
            name={FieldNames.Username}
            control={control}
            render={({ field }) => (
              <FormControl>
                <UiTextField
                  errorMessage={getErrorMessage(FieldNames.Username)}
                  {...field}
                  placeholder={t('signup-modal.username-placeholder')}
                  disabled={isFormDisabled}
                />
              </FormControl>
            )}
          />
          <Controller
            name={FieldNames.Email}
            control={control}
            render={({ field }) => (
              <FormControl>
                <UiTextField
                  errorMessage={getErrorMessage(FieldNames.Email)}
                  {...field}
                  placeholder={t('signup-modal.email-placeholder')}
                  disabled={isFormDisabled}
                />
              </FormControl>
            )}
          />
        </Stack>
        <Button variant='outlined' type='submit' disabled={isFormDisabled} fullWidth>
          {t('signup-modal.submit-lbl')}
        </Button>
      </UiDialogContent>
    </Dialog>
  )
}
