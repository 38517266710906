import {
  Box,
  Button,
  Dialog,
  DialogProps,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Icons } from '@/enums'
import { UiDialogContent, UiIcon } from '@/ui'

export default function InstallMetaMaskModal(props: DialogProps) {
  const { palette, spacing } = useTheme()
  const { t } = useTranslation()

  return (
    <Dialog
      {...props}
      PaperProps={{
        noValidate: true,
        position: 'relative',
        ...props.PaperProps,
        sx: { width: 470, ...props.PaperProps?.sx },
      }}
    >
      <IconButton
        onClick={e => props?.onClose?.(e, 'backdropClick')}
        aria-label='close'
        sx={{
          position: 'absolute',
          top: spacing(6),
          right: spacing(6),
        }}
      >
        <UiIcon name={Icons.Close} size={5} />
      </IconButton>

      <UiDialogContent
        display='flex'
        flexDirection='column'
        alignItems='center'
        gap={6}
        px={6}
        py={8}
        textAlign='center'
      >
        <Box
          component='img'
          src='/imgs/thoughtful-quokka.webp'
          height={112}
          sx={{ objectFit: 'cover' }}
        />

        <Stack spacing={2} alignItems='center'>
          <Typography variant='subtitle2'>{t('install-metamask-modal.title')}</Typography>
          <Typography variant='body3' color={palette.text.secondary} maxWidth={360}>
            {t('install-metamask-modal.description')}
          </Typography>
        </Stack>

        <Button
          variant='outlined'
          fullWidth
          component={Link}
          href='https://metamask.io/download'
          target='_blank'
          rel='noreferrer'
          startIcon={<UiIcon name={Icons.Metamask} size={5} />}
          onClick={e => props?.onClose?.(e, 'backdropClick')}
        >
          {t('install-metamask-modal.install-lbl')}
        </Button>
      </UiDialogContent>
    </Dialog>
  )
}
