import {
  Divider,
  IconButton,
  IconButtonProps,
  Link,
  MenuItem,
  Stack,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AppMenu, ThemeSwitcher } from '@/common/index'
import { Icons, RoutePaths } from '@/enums'
import { authStore } from '@/store'
import { UiIcon } from '@/ui'

type Props = IconButtonProps

const SettingsMenu = ({ ...rest }: Props) => {
  const { palette, breakpoints } = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const menuItemSx: SxProps = { minHeight: 36, p: 2, m: 0 }

  const menuLinks = [
    {
      icon: Icons.FileList2Line,
      label: t('app-navbar.terms-conditions-lbl'),
      href: '/terms',
    },
    {
      icon: Icons.ShieldLine,
      label: t('app-navbar.privacy-policy-lbl'),
      href: '/privacy',
    },
  ]

  return (
    <>
      <Tooltip title={t('app-navbar.settings-tooltip')} placement='right' enterDelay={500}>
        <IconButton
          {...rest}
          sx={{
            px: { xs: 2.5, md: 4 },
            py: { xs: 2.5, md: 3 },
            borderRadius: { xs: 100, md: 3 },
            backgroundColor: anchorEl
              ? palette.action.active
              : { xs: palette.action.active, md: 'transparent' },
            color: anchorEl ? palette.text.primary : palette.text.secondary,
            '&:hover': {
              backgroundColor: anchorEl ? palette.action.active : palette.action.hover,
              color: palette.text.primary,
            },
            ...rest.sx,
          }}
          onClick={event => setAnchorEl(event.currentTarget)}
        >
          <UiIcon
            name={anchorEl ? Icons.Settings4Fill : Icons.Settings4Line}
            size={5}
            color='inherit'
          />
        </IconButton>
      </Tooltip>
      <AppMenu
        anchorEl={anchorEl}
        id='settings-menu'
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        MenuListProps={{ sx: { width: 234 } }}
        sx={{
          transform: 'translateY(40px)',
          [breakpoints.up('md')]: {
            transform: 'translateY(-28px)',
          },
        }}
        onClose={() => setAnchorEl(null)}
      >
        <Stack spacing={3}>
          <ThemeSwitcher />
          <Divider />
          <Stack spacing={2}>
            {menuLinks.map(({ icon, label, href }, idx) => (
              <MenuItem
                key={idx}
                component={Link}
                href={href}
                target='_blank'
                rel='noreferrer'
                sx={menuItemSx}
              >
                <Stack direction='row' alignItems='center' spacing={3}>
                  <UiIcon name={icon} color={palette.text.secondary} size={5} />
                  <Typography variant='buttonMedium' color={palette.text.primary}>
                    {label}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </Stack>
          <Divider />
          <MenuItem
            component={Stack}
            direction='row'
            alignItems='center'
            spacing={3}
            sx={menuItemSx}
            onClick={() => {
              authStore.signOut()
              navigate(RoutePaths.Home)
            }}
          >
            <UiIcon name={Icons.LogoutCircleRLine} color={palette.error.dark} size={5} />
            <Typography variant='buttonMedium' color={palette.error.dark}>
              {t('app-navbar.logout-lbl')}
            </Typography>
          </MenuItem>
        </Stack>
      </AppMenu>
    </>
  )
}

export default SettingsMenu
