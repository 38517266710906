import { BN } from '@distributedlab/tools'
import {
  Button,
  Card,
  Dialog,
  DialogProps,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Confetti from 'react-confetti-boom'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { AccountUpdate, AccountUpdateMeta, markAccountUpdateAsRead } from '@/api/modules/account'
import { getMarketById } from '@/api/modules/markets'
import { getEvents } from '@/api/modules/points'
import { ZERO_BN } from '@/constants'
import { Icons, RoutePaths } from '@/enums'
import { ErrorHandler, formatAmount, formatNumber, getQuestionTokenLabel } from '@/helpers'
import { useLoading } from '@/hooks'
import { authStore, useAuthState, useWeb3State } from '@/store'
import { UiIcon, UiSubtractCard } from '@/ui'

import LogoAvatar from './LogoAvatar'

type Props = {} & Omit<Omit<DialogProps, 'open'>, 'onClose'>

export function CongratsModal({ ...rest }: Props) {
  const { palette } = useTheme()

  const { accountUpdates } = useAuthState()
  const { provider } = useWeb3State()

  const marketUpdates = useMemo((): AccountUpdate<'market_resolved'>[] => {
    const res = accountUpdates.filter(update => update.update_type === 'market_resolved')

    return (res as AccountUpdate<'market_resolved'>[]) || []
  }, [accountUpdates])

  const [isShown, setIsShown] = useState(false)

  const handleClose = useCallback(async () => {
    try {
      setIsShown(false)
      if (provider.address) {
        await markAccountUpdateAsRead(provider.address, marketUpdates[0].id)
      }
      await authStore.loadAccountUpdates()
    } catch (error) {
      ErrorHandler.process(error)
    }
  }, [marketUpdates, provider.address])

  useEffect(() => {
    setIsShown(!!marketUpdates.length)
  }, [marketUpdates])

  if (!marketUpdates.length) return null

  return (
    <Dialog
      {...rest}
      open={isShown}
      onClose={handleClose}
      PaperProps={{
        ...rest.PaperProps,
        sx: {
          maxWidth: 480,
          width: '100%',
          borderRadius: 6,
          overflow: 'hidden',

          background: `${palette.background.light}`,
          ...rest.PaperProps?.sx,
        },
      }}
    >
      <CongratsModalContent meta={marketUpdates[0].meta} onClose={handleClose} />
    </Dialog>
  )
}

type CongratsModalContentProps = {
  onClose: () => void
  meta: AccountUpdateMeta['market_resolved']
}

export function CongratsModalContent({ meta, onClose }: CongratsModalContentProps) {
  const { palette } = useTheme()
  const { t } = useTranslation()

  const {
    data: market,
    isEmpty,
    isLoading,
    isLoadingError,
  } = useLoading(undefined, async () => {
    const eventsResponse = await getEvents({
      query: {
        'filter[meta.static.name]': 'first_bet',
      },
    })

    const { data } = await getMarketById(meta.market_id, {
      query: { include: ['questions.tokens'] },
    })

    const firstBetEvent = eventsResponse.data.find(
      el => el.meta?.dynamic?.market_id === meta.market_id,
    )

    const isFirstBet = Boolean(firstBetEvent)

    const bonusRewardsAmount = firstBetEvent?.points_amount

    return { ...data, isFirstBet, bonusRewardsAmount }
  })

  const marketQuestion = useMemo(() => {
    return market?.questions?.find(el => el.id === meta.question_id) || null
  }, [market, meta])

  const marketQuestionOutcome = useMemo(() => marketQuestion?.reported_outcome, [marketQuestion])

  const isWon = marketQuestionOutcome === meta.outcome

  // shares
  const quantityBN = BN.fromBigInt(meta.shares_amount, 18)

  // bet
  const positionBN = BN.fromBigInt(meta.points_amount, 18)

  const positionRewardBN = isWon ? quantityBN.sub(positionBN) : ZERO_BN.sub(positionBN)

  const bonusRewardsAmountBN = BN.fromRaw(market?.bonusRewardsAmount || 0, 18)

  const totalRewardBN = positionRewardBN.add(bonusRewardsAmountBN)

  const isProfitInTotal = totalRewardBN.isPositive

  const navigate = useNavigate()

  const shareOnX = () => {
    const text = t('congrats-modal.x-share-text', { amount: formatAmount(totalRewardBN) })

    const path = authStore.account?.referral_code
      ? generatePath(RoutePaths.InvitationAlias, { code: authStore.account.referral_code })
      : RoutePaths.Market
    const link = window.origin + path

    window.open(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(link)}`,
      '_blank',
    )

    onClose()
  }

  const handleFailedClick = () => {
    navigate(RoutePaths.Market)
    onClose()
  }

  if (isLoading) return null

  if (isLoadingError || isEmpty) {
    onClose()
    return null
  }

  return (
    <>
      {isWon && (
        <Confetti
          mode='fall'
          particleCount={65}
          shapeSize={15}
          colors={[palette.success.main, palette.primary.light, palette.primary.dark]}
        />
      )}
      <Stack
        sx={{
          flex: 1,
          background: isWon
            ? 'linear-gradient(180deg, rgba(54, 110, 36, 0.32) 0, rgba(54, 110, 36, 0) 130px)'
            : 'linear-gradient(180deg, rgba(221, 59, 54, 0.32) 0%, rgba(119, 32, 29, 0) 130px)',
          p: 6,
          height: 580,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 20,
            right: 24,
            zIndex: 3,
          }}
        >
          <UiIcon name={Icons.Close} size={5} />
        </IconButton>

        <Stack
          spacing={6}
          sx={{
            zIndex: 2,
          }}
        >
          <LogoAvatar
            src={market?.metadata.logo_url}
            alt={market?.metadata.title}
            size={22}
            fallback={<UiIcon name={Icons.App} size={18} />}
            borderColor={palette.background.light}
            sx={{
              borderWidth: 4,
              borderStyle: 'solid',
              alignSelf: 'center',
              backgroundColor: palette.background.light,
            }}
          />

          <Stack spacing={2} sx={{ textAlign: 'center' }}>
            <Typography variant='subtitle2'>
              {isWon ? t('congrats-modal.success.title') : t('congrats-modal.fail.title')}
            </Typography>
            <Typography variant='body3' color={palette.text.secondary}>
              {isWon ? t('congrats-modal.success.subtitle') : t('congrats-modal.fail.subtitle')}
            </Typography>

            <Stack
              direction='row'
              spacing={2}
              alignItems='center'
              sx={{
                alignSelf: 'center',
              }}
            >
              <Typography
                variant='subtitle1'
                color={isWon ? palette.primary.main : palette.error.main}
                align='center'
              >
                {isProfitInTotal && '+'}
                {formatAmount(totalRewardBN)}
              </Typography>
              <UiIcon
                name={Icons.Ion}
                size={6}
                color={isWon ? palette.primary.main : palette.error.main}
              />
            </Stack>

            {market?.isFirstBet && (
              <Typography variant='body3' color={palette.text.secondary}>
                {isWon
                  ? t('congrats-modal.success.bonus', {
                      amount: formatNumber(market?.bonusRewardsAmount || 0),
                    })
                  : t('congrats-modal.fail.bonus', {
                      amount: formatNumber(market?.bonusRewardsAmount || 0),
                    })}
              </Typography>
            )}
          </Stack>

          <Card
            sx={{
              background: isWon ? palette.primary.light : palette.error.light,
              borderRadius: 4,
              p: 2,
            }}
          >
            <Stack spacing={2}>
              <Stack py={1} px={4}>
                <Typography variant='subtitle4'>{t('congrats-modal.position-lbl')}</Typography>
              </Stack>

              <Stack spacing={0.25}>
                <UiSubtractCard
                  parentBackgroundColor={isWon ? palette.primary.light : palette.error.light}
                  direction='row'
                  spacing={4}
                >
                  <Stack spacing={1} alignItems='start'>
                    <Typography variant='subtitle4'>{marketQuestion?.metadata.title}</Typography>
                    <Typography
                      variant='buttonSmall'
                      minWidth={32}
                      textAlign='center'
                      px='6px'
                      py='4px'
                      borderRadius={100}
                      bgcolor={meta.outcome ? palette.primary.main : palette.secondary.main}
                      color={palette.common.white}
                    >
                      {getQuestionTokenLabel(marketQuestion, meta.outcome)}
                    </Typography>
                  </Stack>

                  <LogoAvatar
                    src={marketQuestion?.metadata.logo_url}
                    alt={marketQuestion?.metadata.title}
                    size={10}
                    marginLeft='auto'
                    fallback={<UiIcon name={Icons.App} size={10} />}
                  />
                </UiSubtractCard>

                <UiSubtractCard
                  parentBackgroundColor={isWon ? palette.primary.light : palette.error.light}
                  direction='row'
                  spacing={15}
                  subtractPosition='top'
                >
                  <Stack>
                    <Typography variant='overline2' color={palette.text.secondary}>
                      {t('congrats-modal.position-th')}
                    </Typography>

                    <Stack direction='row' spacing={2} alignItems='center'>
                      <Typography variant='subtitle4'>{formatAmount(positionBN)}</Typography>
                      <UiIcon name={Icons.Ion} size={4} />
                    </Stack>
                  </Stack>

                  <Stack
                    sx={{
                      marginLeft: 'auto',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Typography variant='overline2' color={palette.text.secondary}>
                      {isWon ? t('congrats-modal.win-th') : t('congrats-modal.lose-th')}
                    </Typography>

                    <Stack direction='row' spacing={2} alignItems='center'>
                      <Typography
                        variant='subtitle4'
                        color={isWon ? palette.primary.main : palette.error.main}
                      >
                        {isWon && '+'}
                        {formatAmount(positionRewardBN)}
                      </Typography>
                      <UiIcon
                        name={Icons.Ion}
                        size={4}
                        color={isWon ? palette.primary.main : palette.error.main}
                      />
                    </Stack>
                  </Stack>
                </UiSubtractCard>
              </Stack>
            </Stack>
          </Card>

          <Button
            variant='outlined'
            onClick={() => {
              if (isWon) {
                shareOnX()

                return
              }

              handleFailedClick()
            }}
          >
            <Stack spacing={4} alignItems='center' direction='row'>
              {isWon ? (
                <>
                  <UiIcon name={Icons.TwitterXFill} />
                  {t('congrats-modal.success.share-btn')}
                </>
              ) : (
                <>{t('congrats-modal.fail.explore-market-btn')}</>
              )}
            </Stack>
          </Button>
        </Stack>
      </Stack>
    </>
  )
}
